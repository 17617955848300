import { Button, Card, CircularProgress, TextField, Typography } from "@material-ui/core";
import EstilosGlobales from "../../toremp/EstilosGlobales";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import React,{ useState,useEffect, useContext,useCallback } from "react";
import { validate, /*clean,*/ format } from "rut.js";
import axios from 'axios'
import LinearProgressMine from '../LinearProgressMine.jsx'
import Swal from '../../context/SwalAlert.jsx'
import DatosConfigContext from '../../context/config.jsx'

import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

function ContenidoRegistrar({gtoken,handleReCaptchaVerify}) {
    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Registrar" }];
    const ClaseInputs = "col-md-6 text-center";

    const [EstadoSubmited, setEstadoSubmited] = useState(false);
    const [RegistroSuccess,setRegistroSuccess]= useState(false);

    const [DatosRegistro, setDatosRegistro] = useState({
        nombre: "",
        RUT: "",
        razon_social: "",
        RUT_razon_social: "",
        telefono: "",
        ciudad: "",
        direccion: "",
        email: "",
        password: "",
        password_confirm: "",
    });

    const [ErrorCampos, setErrorCampos] = useState({
        nombre: { error: false, texto: "" },
        RUT: { error: false, texto: "" },
        razon_social: { error: false, texto: "" },
        RUT_razon_social: { error: false, texto: "" },
        telefono: { error: false, texto: "" },
        ciudad: { error: false, texto: "" },
        direccion: { error: false, texto: "" },
        email: { error: false, texto: "" },
        password: { error: false, texto: "" },
        password_confirm: { error: false, texto: "" },
        gcaptcha: { error: false, texto: "" }
    });
    const [GCaptchaConfig, setGCaptchaConfig] = useState({
        cargado: false,
        gsite_key: "",
    });

    const handleSubmit =async (e) => {
        e.preventDefault();

        if(EstadoSubmited){//Si esta en submit 
            return false;
        }

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            nombre: { error: false, texto: "" },
            RUT: { error: false, texto: "" },
            razon_social: { error: false, texto: "" },
            RUT_razon_social: { error: false, texto: "" },
            telefono: { error: false, texto: "" },
            ciudad: { error: false, texto: "" },
            direccion: { error: false, texto: "" },
            email: { error: false, texto: "" },
            password: { error: false, texto: "" },
            password_confirm: { error: false, texto: "" },
            gcaptcha: { error: false, texto: "" }
        };

        if (DatosRegistro.nombre.length <= 0) {
            ErrorCampoPre.nombre.error = true;
            ErrorCampoPre.nombre.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.nombre.error = false;

        if (DatosRegistro.RUT.length <= 0) {
            ErrorCampoPre.RUT.error = true;
            ErrorCampoPre.RUT.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.RUT.error = false;

        if (DatosRegistro.telefono.length <= 0) {
            ErrorCampoPre.telefono.error = true;
            ErrorCampoPre.telefono.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.telefono.error = false;

        if (DatosRegistro.ciudad.length <= 0) {
            ErrorCampoPre.ciudad.error = true;
            ErrorCampoPre.ciudad.texto = "Debe elegir una ciudad";
            campos_requeridos += 1;
        } else ErrorCampoPre.ciudad.error = false;

        if (DatosRegistro.direccion.length <= 0) {
            ErrorCampoPre.direccion.error = true;
            ErrorCampoPre.direccion.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.direccion.error = false;

        if (DatosRegistro.email.length <= 0) {
            ErrorCampoPre.email.error = true;
            ErrorCampoPre.email.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.email.error = false;

        if (DatosRegistro.password.length <= 0) {
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = MsjeCampoReq;
            campos_requeridos += 1;
        }else if(DatosRegistro.password.length>0 && DatosRegistro.password.length<8){
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = "Password requiere al menos 8 caracteres";
            campos_requeridos += 1;
        } 
        else ErrorCampoPre.password.error = false;

        if (DatosRegistro.password_confirm.length <= 0) {
            ErrorCampoPre.password_confirm.error = true;
            ErrorCampoPre.password_confirm.texto = MsjeCampoReq;
            campos_requeridos += 1;
        }else if(DatosRegistro.password!==DatosRegistro.password_confirm){
            ErrorCampoPre.password_confirm.error = true;
            ErrorCampoPre.password_confirm.texto = "Passwords NO coinciden";
            campos_requeridos += 1;
        }
        else ErrorCampoPre.password_confirm.error = false;

        if (gtoken.length <= 0) {
            ErrorCampoPre.gcaptcha.error = true;
            ErrorCampoPre.gcaptcha.texto = "Debe completar el Captcha";
            campos_requeridos+=1;
        }

        setErrorCampos(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        }


        //Capa GCaptcha
        await handleReCaptchaVerify();
        var datos = new FormData();
        datos.append("g-recaptcha-response", gtoken);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/googleCaptcha/validar_captcha.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                setEstadoSubmited(true);

                //INI SUBMIT
                var datos = new FormData();
                datos.append("nombre",DatosRegistro.nombre);
                datos.append("RUT",DatosRegistro.RUT);
                datos.append("razon_social",DatosRegistro.razon_social);
                datos.append("RUT_razon_social",DatosRegistro.RUT_razon_social);
                datos.append("telefono",DatosRegistro.telefono);
                datos.append("ciudad",DatosRegistro.ciudad);
                datos.append("direccion",DatosRegistro.direccion);
                datos.append("email",DatosRegistro.email);
                datos.append("password",DatosRegistro.password);

                axios({
                    url: process.env.PUBLIC_URL+"/assets/api/cuentas/registrar_cuenta.php",
                    method:"post",
                    data:datos
                }).then((resp)=>{
                    //console.log(resp);
                    var res = resp.data;
                    //console.log(res);
                    if(res[0]==="realizado"){
                        setRegistroSuccess(true);
                    }else if(res[0]==="error_noingresado"){
                        Swal.fire({
                            title:"Error Ingreso",
                            html:"Hubo un problema al ingresar la nueva cuenta. Recargue e intente nuevamente. Si el problema persiste, contáctese con soporte técnico",
                            icon:"warning",
                            confirmButtonText:"Aceptar",
                            confirmButtonColor:EstilosGlobales.colorSecundario
                        })   
                        return false;
                    }else if(res[0]==="error_existe"){
                        Swal.fire({
                            title:"Cuenta ya existente",
                            html:"El <b>email</b> o el <b>RUT</b> ya se encuentra registrado en nuestra base de datos.",
                            icon:"warning",
                            confirmButtonText:"Aceptar",
                            confirmButtonColor:EstilosGlobales.colorSecundario
                        });
                        setErrorCampos({
                            ...ErrorCampos,
                            email:{error:true,texto:"Email o RUT ya existe."},
                            RUT:{error:true,texto:"Email o RUT ya existe."},
                        });
                        setEstadoSubmited(false);
                    }else{
                        Swal.fire({
                            title:"Error inesperado",
                            html:"Ocurrió un Error Inesperado <br/>Recargue e intente nuevamente realizar la operación. Si el problema persiste, contáctese con soporte.",
                            icon:"warning",
                            confirmButtonText:"Aceptar",
                            confirmButtonColor:EstilosGlobales.colorSecundario
                        })                 
                        return false;
                    }
                })
            } else if (res[0] === "error_rechazado") {
                //recaptchaRef.current.reset();
                setErrorCampos({
                    ...ErrorCampos,
                    gcaptcha:{
                        error: true,
                        texto: "Captcha inválido, reingrese.",
                    }
                })
            }
        });
    };

    useEffect(() => {
        const cargarCaptcha = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/googleCaptcha/cargar_captcha.php",
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setGCaptchaConfig({
                        cargado: true,
                        gsite_key: res["gsite_key"],
                    });
                }
            });
        };

        cargarCaptcha();
    }, []);
    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo>Registrar</Titulo>
                    {
                    RegistroSuccess?
                    <MensajeRegistrado/> 
                    : 
                    <form
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                        className="row py-4 justify-content-center"
                    >
                        <div className="col-md-8 col-sm-10 col-12">
                            <Card className="py-4 px-2 text-center">
                                <Titulo className="px-5 mb-4">
                                    Registrar Cuenta
                                </Titulo>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="Nombre Apellido"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    nombre: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.nombre.error}
                                            helperText={
                                                ErrorCampos.nombre.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="RUT"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    RUT: e.target.value,
                                                });
                                            }}
                                            onBlur={(e) => {
                                                var RUT_final = "";
                                                RUT_final = e.target.value;
                                                format(RUT_final);
                                                if (validate(RUT_final)) {
                                                    RUT_final = format(
                                                        RUT_final
                                                    );
                                                    setDatosRegistro({
                                                        ...DatosRegistro,
                                                        RUT: RUT_final,
                                                    });
                                                    setErrorCampos({
                                                        ...ErrorCampos,
                                                        RUT: {
                                                            error: false,
                                                            texto: "",
                                                        },
                                                    });
                                                } else {
                                                    setDatosRegistro({
                                                        ...DatosRegistro,
                                                        RUT: "",
                                                    });
                                                    setErrorCampos({
                                                        ...ErrorCampos,
                                                        RUT: {
                                                            error: true,
                                                            texto:
                                                                "Rut inválido",
                                                        },
                                                    });
                                                }
                                            }}
                                            value={DatosRegistro.RUT}
                                            error={ErrorCampos.RUT.error}
                                            helperText={ErrorCampos.RUT.texto}
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="Razon Social (Opcional)"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    razon_social:
                                                        e.target.value,
                                                });
                                            }}
                                            error={
                                                ErrorCampos.razon_social.error
                                            }
                                            helperText={
                                                ErrorCampos.razon_social.texto
                                            }
                                            value={DatosRegistro.razon_social}
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="RUT Razon Social (Opcional)"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    RUT_razon_social:
                                                        e.target.value,
                                                });
                                            }}
                                            onBlur={(e) => {
                                                var RUT_final = "";
                                                RUT_final = e.target.value;
                                                format(RUT_final);
                                                if (validate(RUT_final)) {
                                                    RUT_final = format(
                                                        RUT_final
                                                    );
                                                    setDatosRegistro({
                                                        ...DatosRegistro,
                                                        RUT_razon_social: RUT_final,
                                                    });
                                                    setErrorCampos({
                                                        ...ErrorCampos,
                                                        RUT_razon_social: {
                                                            error: false,
                                                            texto: "",
                                                        },
                                                    });
                                                } else {
                                                    setDatosRegistro({
                                                        ...DatosRegistro,
                                                        RUT_razon_social: "",
                                                    });
                                                    setErrorCampos({
                                                        ...ErrorCampos,
                                                        RUT_razon_social: {
                                                            error: true,
                                                            texto:
                                                                "Rut inválido",
                                                        },
                                                    });
                                                }
                                            }}
                                            value={
                                                DatosRegistro.RUT_razon_social
                                            }
                                            error={
                                                ErrorCampos.RUT_razon_social
                                                    .error
                                            }
                                            helperText={
                                                ErrorCampos.RUT_razon_social
                                                    .texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="Telefono"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    telefono: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.telefono.error}
                                            helperText={
                                                ErrorCampos.telefono.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <SeleccionLocalidad
                                        className={ClaseInputs}
                                        DatosRegistro={{DatosRegistro,setDatosRegistro}}
                                        ErrorCampos={{ErrorCampos,setErrorCampos}}
                                    ></SeleccionLocalidad>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="text"
                                            label="Direccion"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    direccion: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.direccion.error}
                                            helperText={
                                                ErrorCampos.direccion.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="email"
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    email: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.email.error}
                                            helperText={
                                                ErrorCampos.email.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="password"
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    password: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.password.error}
                                            helperText={
                                                ErrorCampos.password.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <TextField
                                            type="password"
                                            label="Reingrese Password"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setDatosRegistro({
                                                    ...DatosRegistro,
                                                    password_confirm: e.target.value,
                                                });
                                            }}
                                            error={ErrorCampos.password_confirm.error}
                                            helperText={
                                                ErrorCampos.password_confirm.texto
                                            }
                                        ></TextField>
                                    </div>
                                </div>
                                {ErrorCampos.gcaptcha.error?
                                    <div className="row justify-content-center mb-1">
                                        <Typography variant="body2" className="text-danger font-weight-bold">
                                            * {ErrorCampos.gcaptcha.texto} *
                                        </Typography>
                                            </div>:""
                                }
                                <div className="row mt-3 justify-content-center">
                                    <div className={ClaseInputs}>
                                        <Button
                                            type="submit"
                                            className="text-white"
                                            variant="contained"
                                            size="large"
                                            style={{
                                                backgroundColor:
                                                    EstilosGlobales.colorPrimario,
                                            }}
                                        >
                                            Registrar
                                        </Button>
                                    </div>
                                </div>
                                {
                                    EstadoSubmited?
                                    <>
                                        <LinearProgressMine className="my-2"/>
                                        <div className="row justify-content-center mt-2">
                                            <Typography className="font-weight-bold">Cargando</Typography>
                                        </div>
                                    </>
                                    :""
                                }
                            </Card>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </div>
    );
}

function SeleccionLocalidad(props) {

    let {DatosRegistro,setDatosRegistro} = props.DatosRegistro;
    let {ErrorCampos}=props.ErrorCampos;
    const [Regiones,setRegiones]=useState([]);
    const [Region,setRegion]=useState("");
    const [Estado,setEstado]=useState("inicio");
    const [Ciudades,setCiudades]=useState([]);
    const [SelCiudad,setSelCiudad]=useState(true);

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarRegiones();
        }
    })

    const cargarRegiones=()=>{
        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/registrar_regiones_cargar.php" 
        }).then((resp)=>{
            var res = resp.data;
            setRegiones(res);
            setEstado("cargado");
        })
    }

    const handleCambioRegion=(e)=>{
        var datos = new FormData();
        datos.append("region",e.target.value);

        axios({
           url: process.env.PUBLIC_URL+"/assets/api/cuentas/registrar_ciudades_cargar.php" ,
           method:"post",
           data:datos
        }).then((resp)=>{
            var res = resp.data;
            setCiudades(res);
            setSelCiudad(false);
        })
    }


    if(Estado==="cargado"){
        return (
            <div className="d-flex flex-column jutify-content-center align-items-center">
                <div className={props.className}>
                    <select
                        value={Region}
                        onChange={(e) => {
                            handleCambioRegion(e);
                            setRegion(e.target.value);
                            setDatosRegistro({...DatosRegistro,ciudad:""});
                        }}
                        className="form-control my-2"
                    >
                        <option value="">Seleccione Región</option>
                        {Regiones.map((region, index) => {
                            return (
                                <option key={index} value={region.region}>
                                    {region.nombre}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className={props.className}>
                    <select
                        value={DatosRegistro.ciudad}
                        onChange={(e) => {
                            setDatosRegistro({...DatosRegistro,ciudad:e.target.value}) 
                        }}
                        className="form-control my-2"
                        disabled={SelCiudad}
                    >
                        <option value="">Seleccione Ciudad</option>
                        {Ciudades.map((ciudad, index) => {
                            return (
                                <option key={index} value={ciudad.nombre}>
                                    {ciudad.nombre}
                                </option>
                            );
                        })}
                    </select>
                    {
                        ErrorCampos.ciudad.error?
                        <Typography className="font-weight-bold text-danger" variant="body2">* {ErrorCampos.ciudad.texto} *</Typography>
                        :
                        ""
                    }
                </div>
            </div>
        );
    }else{
        return(
            <div className="d-flex flex-row jutify-content-center align-items-center">
                <CircularProgress></CircularProgress> 
                <Typography className="ml-2 font-weight-bold">
                    Cargando
                </Typography>
            </div>
        );
    }
}


function MensajeRegistrado(){
    const {DatosConfig} = useContext(DatosConfigContext);
    return(
        <div className="row py-3 justify-content-center mx-0">
            <Card className="col-md-6" style={{minHeight:"300px"}}>
                <div className="px-3 py-3">
                    <Titulo>
                        Cuenta Registrada
                    </Titulo>
                    <div className="text-center py-5">
                        <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}}>
                            La cuenta fue registrada con éxito
                        </Typography>
                        <Typography className="">
                            Se ha enviado un <u>correo electrónico</u> con la información para activar su cuenta <b className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}}>{DatosConfig.Empresa.nombre}</b>.
                        </Typography>
                            <Typography variant="caption" className="font-weight-bold" style={{color:EstilosGlobales.colorSecundario}}>
                            NOTA: En caso de no visualizar el correo, revise carpeta SPAM.
                        </Typography>
                    </div>
                </div>
            </Card>
        </div>
    );
}


const GCaptchaParentComponent=()=>{
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [gtoken,setGtoken] = useState('');

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const getTokenResult = await executeRecaptcha('yourAction');

        if(getTokenResult){
            setGtoken(getTokenResult);
        }
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return(
        <ContenidoRegistrar handleReCaptchaVerify={handleReCaptchaVerify} gtoken={gtoken} />
    )
}

export default GCaptchaParentComponent;