//import Titulo from "./TituloTorEmp";
//import EstilosGlobales from "../EstilosGlobales";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect,useState} from 'react'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import axios from 'axios'
import './ProductoEspecificacionContenido.css';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {/*<Typography>{children}</Typography>*/}
                        {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let {DatosProducto} = props;
    var counterInterno = 0;

    return (
    <div className="bg-white rounded shadow-sm mt-2 px-1 px-md-3 py-2">
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="primary"
                >
                    {
                        DatosProducto.productos_esp_index.map((especificacion,index)=>{
                            
                            if(especificacion.tipo && especificacion.tipo==="texto_enriquecido"){
                                return(
                                    <Tab key={index} label={especificacion.nombre} {...a11yProps(index)} />
                                )
                            }else return false;
                        })
                    }
                </Tabs>
            </Box>
            {
                DatosProducto.productos_esp_index.map((especificacion,index)=>{
                    if(especificacion.tipo && especificacion.tipo==="texto_enriquecido"){
                        counterInterno+=1;
                        return(
                            <TabPanel value={value} key={index} index={counterInterno-1}>
                                <QuillCargador Contenido={DatosProducto[especificacion.variable]}/>
                            </TabPanel>
                        )
                    }else return false;
                })
            }
        </Box>
    </div>
    );
}

function QuillCargador(props){

    const [ContenidoCargado,setContenidoCargado] = useState();
    const [Cargado,setCargado] = useState(false);

    useEffect(()=>{
        const cargarContenido = (contenido_cargar) => {
            //console.log("Cargando Contenido Post");
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/datos/ficha_tecnica/" +
                    contenido_cargar
            }).then((resp) => {
                var res = resp.data;
                setContenidoCargado(res);
                setCargado(true);
                //console.log(res);
            });
        };

        cargarContenido(props.Contenido);
    },[])

    if(Cargado){
        return(
            <>
                <QuillContainer Contenido={ContenidoCargado} className="">
                </QuillContainer>
            </>
        )
    }else{
        return(
            <div className="">
            </div>
        )
    }
}

const QuillContainer= (props) => {
    const theme = 'snow';
    // const theme = 'bubble';
    
    const modules = {
        toolbar: false        
    };

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarHorario=()=>{
            //console.log("Cargando");
            quill.setContents((props.Contenido));
            //console.log(props.Contenido);
        }

        if(Estado==="inicio"){
            if(quill){
                cargarHorario();
                quill.disable();
            }
        }   

    }, [quill]);

    return (
        <div className="text-dark border-0">
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
};

