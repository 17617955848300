import { useEffect, useState, useRef,Fragment } from "react";
import axios from "axios";
import {
    CircularProgress,
    Fade,
    Typography,
    Autocomplete,
    TextField,
    Button,
    Chip,
    IconButton,
} from "@mui/material";
import EstilosGlobales from "../../toremp/EstilosGlobales.jsx";
import numberFormat from "../../context/numberFormat";
import CloseIcon from "@mui/icons-material/Close";
import Alerta,{useAlert} from "../utilitys/Alerta.jsx";

export default function FormChilexpress(props) {
    const buscadorCallesRef = useRef(null);

    let { DatosVenta, setDatosVenta, setDatosNoSubmit, DatosNoSubmit,DatosFormChilexpress,setDatosFormChilexpress,Sidenc } =
        props.Propiedades;

    const [Regiones, setRegiones] = useState([]);
    const [Comunas, setComunas] = useState([]);
    const [Servicios, setServicios] = useState([]);
    const [ComunaSinCobertura, setComunaSinCobertura] = useState(false);
    const [Cargando, setCargando] = useState(true);
    const [Calles, setCalles] = useState([]);
    const [CalleBuscada, setCalleBuscada] = useState("");
    const [DisableBuscador, setDisableBuscador] = useState(false);


    const { AlertaProps,setAlertaProps } = useAlert();

    useEffect(() => {
        const cargarRegiones = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/despachos/submod_chilexpress/cobertura.regiones.php",
            })
                .then((resp) => {
                    //console.log(resp);
                    if (resp.status === 200 && resp.data && resp.data.regions) {
                        setRegiones(resp.data.regions);
                        setCargando(false);
                    }
                })
                .catch((resp) => {
                    //console.log(resp);
                });
        };

        if (
            DatosVenta.tipo_despacho === "DespachoChilexpress" &&
            Regiones.length <= 0
        )
            cargarRegiones();

    }, [DatosVenta.tipo_despacho]);

    const cargarComunas = (regionId) => {
        setCargando(true);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despachos/submod_chilexpress/cobertura.comunas.php?Region=" +
                regionId,
        })
            .then((resp) => {
                //console.log(resp);
                if (
                    resp.status === 200 &&
                    resp.data &&
                    resp.data.coverageAreas
                ) {
                    setComunas(resp.data.coverageAreas);
                    setCargando(false);
                }
            })
            .catch((resp) => {
                //console.log(resp);
            });
    };

    useEffect(() => {
        if (DatosFormChilexpress.serviceTypeCode === 0)
            setDatosNoSubmit({
                ...DatosNoSubmit,
                total_despacho: 0,
                peso_despacho: 0,
            });
    }, [DatosFormChilexpress.serviceTypeCode]);

    const handleChangeRegion = (e) => {
        if (e.target.value === DatosFormChilexpress.regionId) return false;
        else if (e.target.value === "") {
            setComunas([]);
            return false;
        }

        cargarComunas(e.target.value);

        setDatosFormChilexpress({
            ...DatosFormChilexpress,
            regionId: e.target.value,
            countyCode: "",
            countyName: "",
            serviceTypeCode: 0,
            streetName: "",
            streetNumber: "",
            infoComplementaria:""
        });
        setCalles([]);
        setCalleBuscada("");
        setDisableBuscador(false);
        setServicios([])
    };

    const handleChangeComunaCotizar = (e) => {
        /*Errores Handle
         *
        {
            "statusCode": 400,
            "message": "BAD_REQ_miss_packageInfo"
        }
        */
        if (Cargando) return false;
        if (e.target.value === DatosFormChilexpress.countyCode) return false;

        const buscarRegistro = Comunas.find(
            (comuna) => e.target.value === comuna.countyCode
        );
        setDatosFormChilexpress({
            ...DatosFormChilexpress,
            countyCode: e.target.value,
            countyName: buscarRegistro.countyName,
            serviceTypeCode: 0,
            streetName: "",
            streetNumber: "",
            infoComplementaria:""
        });

        setCalles([]);
        setCalleBuscada("");
        setDisableBuscador(false);

        setCargando(true);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despachos/submod_chilexpress/cotizar.empresa.php",
            method: "post",
            data: {
                destinationCountyCode: e.target.value,
                sidenc:Sidenc
            },
        })
            .then((resp) => {
                //console.log(resp);
                //if(resp.data) console.log(resp.data);
                if (
                    resp.status === 200 &&
                    resp.data &&
                    resp.data.courierServiceOptions &&
                    resp.data[0] &&
                    resp.data.courierServiceOptions.length > 0
                ) {
                    setServicios(resp.data.courierServiceOptions);
                    setComunaSinCobertura(false);
                    setCargando(false);
                } else if (resp.status === 200 && resp.data && !resp.data[0]) {
                    setCargando(false);
                    setComunaSinCobertura(true);
                    setServicios([]);
                }
            })
            .catch((resp) => {
                //console.log(resp);
                //if(resp.data) console.log(resp.data);
                //if(resp.response) console.log(resp.response);
                if(resp.response.status===400 && resp.response.data && resp.response.data.message==="BAD_REQ_miss_packageInfo"){
                    setCargando(false);
                    setAlertaProps({severity:"warning",content:"Alguno de los productos en el carro de compra NO se encuentran disponible para despacho via Chilexpress. Ingrese al Carro y verifique",display:true})
                }
            });
    };

    const handleChangeSelServicio = (e) => {
        //console.log(e.target.value);
        setDatosFormChilexpress({
            ...DatosFormChilexpress,
            serviceTypeCode: e.target.value,
        });

        const buscarRegistro = Servicios.find(
            (servicio) => servicio.serviceTypeCode == parseInt(e.target.value)
        );
        if (buscarRegistro) {
            setDatosNoSubmit({
                ...DatosNoSubmit,
                total_despacho: buscarRegistro.serviceValue,
                peso_despacho: buscarRegistro.finalWeight,
            });
        } else {
            setDatosNoSubmit({
                ...DatosNoSubmit,
                total_despacho: 0,
                peso_despacho: 0,
            });
        }
    };

    const buscarCalle = (e) => {
        if (Cargando) return false;
        //console.log("CalleBuscada", CalleBuscada);
        //console.log("countyName", DatosFormChilexpress.countyName);
        setAlertaProps({...AlertaProps,display:false})
        setCargando(true);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despachos/submod_chilexpress/cobertura.calle.php",
            method: "post",
            data: {
                calle: CalleBuscada,
                comuna: DatosFormChilexpress.countyName,
            },
        })
            .then((resp) => {
                //console.log(resp);
                var InyCalles = [];
                if (resp.status === 200 && resp.data && resp.data.streets && resp.data.statusCode===0) {
                    resp.data.streets.map((street, index) => {
                        InyCalles.push({ label: street.streetName });
                    });
                    setCalles(InyCalles);
                    setCargando(false);
                } else if(resp.status===200 && resp.data.statusCode==-1 && resp.data.streets.length===0) {
                    setCargando(false)
                    setAlertaProps({severity:"warning",display:true,content:"No se encontraron coincidencias de calle."})
                    setCalles([])
                } else {
                    //setCargando(false)
                }
            })
            .catch((resp) => {
                //console.log(resp);
            });
    };

    const handleSelectTagCalle = (e, value) => {
        if (value && value.label) {
            setDatosFormChilexpress({
                ...DatosFormChilexpress,
                streetName: value.label,
            });

            setCalleBuscada(value.label);
        } else setCalleBuscada("");

        if (value !== null) {
            //console.log("DISABLED SELECTED",value.label);
            setDisableBuscador(true);
        }
    };

    return (
        <div className="px-md-4 px-sm-3 p-1 position-relative">
            <select
                className="form-control"
                onChange={(e) => {
                    handleChangeRegion(e);
                }}
                value={DatosFormChilexpress.regionId}
                name="regionId"
            >
                <option value="">Seleccione Region</option>
                {Regiones.map((region, index) => {
                    return (
                        <option key={index} value={region.regionId}>
                            {region.ineRegionCode} - {region.regionName}
                        </option>
                    );
                })}
            </select>

            {Comunas && Comunas.length > 0 ? (
                <Fragment>
                    <select
                        className="form-control mt-3"
                        onChange={(e) => {
                            handleChangeComunaCotizar(e);
                        }}
                        value={DatosFormChilexpress.countyCode}
                        name="countyCode"
                    >
                        <option value="">Seleccione Comuna</option>
                        {Comunas.map((comuna, index) => {
                            return (
                                <option key={index} value={comuna.countyCode}>
                                    {comuna.countyName}
                                </option>
                            );
                        })}
                    </select>
                    <input type="hidden" name="countyName" value={DatosFormChilexpress.countyName}></input>
                </Fragment>
            ) : (
                ""
            )}

            {Servicios && Servicios.length > 0 && !ComunaSinCobertura ? (
                <select
                    className="form-control mt-3"
                    onChange={(e) => {
                        handleChangeSelServicio(e);
                    }}
                    value={DatosFormChilexpress.serviceTypeCode}
                    name="serviceTypeCode"
                >
                    <option value={0}>Seleccione Servicio</option>
                    {Servicios.map((servicio, index) => {
                        return (
                            <option
                                key={index}
                                value={servicio.serviceTypeCode}
                            >
                                $
                                {numberFormat(
                                    servicio.serviceValue,
                                    0,
                                    ",",
                                    "."
                                )}{" "}
                                - {servicio.serviceDescription}
                            </option>
                        );
                    })}
                </select>
            ) : (
                ""
            )}

            {ComunaSinCobertura ? (
                <div className="">
                    <Typography className="text-danger mt-3 font-weight-bold">
                        * Comuna sin Cobertura de Servicio Chilexpress *
                    </Typography>
                </div>
            ) : (
                ""
            )}

            {DatosFormChilexpress.serviceTypeCode > 0 ? (
                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="">
                        <Autocomplete
                            ref={buscadorCallesRef}
                            freeSolo
                            value={CalleBuscada}
                            onInputChange={(e, inputValue) => {
                                setCalleBuscada(inputValue);
                            }}
                            onChange={handleSelectTagCalle}
                            disabled={DisableBuscador}
                            disablePortal
                            className="mt-3"
                            id="combo-box-demo"
                        //name="streetName"
                            options={Calles}
                            sx={{ width: 300 }}
                            blurOnSelect
                            renderInput={(params) => (
                                <TextField {...params} label="Calle" />
                            )}
                        />
                        <input name="streetName" type="hidden" value={DatosFormChilexpress.streetName}></input>
                    </div>
                    {!DisableBuscador ? (
                        <Button
                            className="ml-2 mt-3"
                            onClick={() => {
                                buscarCalle();
                                buscadorCallesRef.current.focus();
                            }}
                            variant="contained"
                            disabled={CalleBuscada.length>=3?false:true}
                            style={{
                                backgroundColor: CalleBuscada.length>=3?EstilosGlobales.colorPrimario:"",
                            }}
                        >
                            Buscar
                        </Button>
                    ) : (
                        <IconButton
                            className="ml-2 mt-3 text-white"
                            onClick={() => {
                                setCalleBuscada("");
                                setDatosFormChilexpress({
                                    ...DatosFormChilexpress,
                                    streetName: "",
                                });
                                setDisableBuscador(false);
                            }}
                            style={{
                                backgroundColor: EstilosGlobales.colorPrimario,
                            }}
                        >
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    )}
                </div>
            ) : (
                ""
            )}
            {DatosFormChilexpress.streetName !== "" ? (
                <div className="d-inline-flex flex-column justify-content-center">
                    <TextField
                        value={DatosFormChilexpress.streetNumber}
                        label="Numero"
                        className="mt-3"
                        name="streetNumber"
                        style={{ minWidth: "200px" }}
                        onChange={(e) => {
                            if(e.target.value.length>10)return false;
                            setDatosFormChilexpress({
                                ...DatosFormChilexpress,
                                streetNumber: e.target.value
                            });
                        }}
                    ></TextField>
                    <TextField
                        value={DatosFormChilexpress.infoComplementaria}
                        label="Informacion Complementaria"
                        className="mt-3"
                        name="infoComplementaria"
                        style={{ minWidth: "250px" }}
                        onChange={(e) => {
                            if(e.target.value.length>100)return false;
                            setDatosFormChilexpress({
                                ...DatosFormChilexpress,
                                infoComplementaria: e.target.value
                            });
                        }}
                        placeholder="ejemplo: Dpto 301"
                        helperText="Max 100 caracteres."
                    ></TextField>

                </div>
            ) : (
                ""
            )}
            <Alerta className="mt-2" AlertaProps={AlertaProps} setAlertaProps={setAlertaProps}></Alerta>
            <CargadorAbs display={Cargando}></CargadorAbs>
        </div>
    );
}

function CargadorAbs(props) {
    return (
        <Fade in={props.display}>
            <div
                className="position-absolute w-100 h-100"
                style={{ zIndex: "300", top: "0px", left: "0px" }}
            >
                <div
                    className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{ zIndex: "301" }}
                >
                    <CircularProgress
                        style={{ color: EstilosGlobales.colorPrimario }}
                    ></CircularProgress>
                </div>
                <div
                    className="bg-white w-100 h-100"
                    style={{ opacity: "0.5" }}
                ></div>
            </div>
        </Fade>
    );
}
