import { useState, useEffect} from 'react'
import Ruta from '../Ruta'
import SideBar from './SideBar'
import ProductosCatalogo from './ProductosCatalogo'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import LinearProgressMine from '../LinearProgressMine'
import SideBarFiltros from './SideBarFiltros'
import useWindowSize from '../../context/useWindowSize'
//import useWindowScroll from '../../context/useWindowScroll'
import EstilosGlobales from '../../toremp/EstilosGlobales'
import {Button} from '@material-ui/core'
import ListIcon from '@material-ui/icons/List';
import { useSpring, animated } from "react-spring";
//META
import MetaData from '../../context/MetaData'
//REDIR
//import {useHistory} from 'react-router-dom'

const defineMobile=(width)=>{
    switch(width){
        case (0):
            const {innerWidth} = window;
            if((innerWidth>=992) || (innerWidth<=767 && innerWidth>=576))return false;
            else return true;
        case ((width>=992) || (width<=767 && width>=576)):
            return false;
        default:
            return true;
    }
}

export default function ContenidoProductos() {
    const size = useWindowSize();
    //const scroll = useWindowScroll();
    //let history = useHistory();

    let { superfamilia, familia, subfamilia, capa_cargar,menu_load } = useParams();

    const [Rutas, setRutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Cargando..." }
    ]);

    const [Estado, setEstado] = useState("inicio");
    const [Productos, setProductos] = useState();
    const [DatosCarga, setDatosCarga] = useState({
        limite_pagina: defineMobile(size.width)?8:9,
        pagina: 1,
        orden: "sin_orden",
        cant_paginas: 1,
        cant_total: 1,
        
    })

    const [Filtros, setFiltros] = useState({
        destacado: false,
        oferta: false,
        marca: "",
        carac_principal:"",
        carac_secundaria:"",
        filtros_valor:{
            esp1:"",
            esp2:"",
            esp3:"",
            esp4:"",
            esp5:"",
            esp6:"",
            esp7:"",
            esp8:"",
            esp9:"",
            esp10:"",
        },
        submited:false
    })
    const ResetFiltros = {
        destacado: false,
        oferta: false,
        marca: "",
        carac_principal:"",
        carac_secundaria:"",
        filtros_valor:{
            esp1:"",
            esp2:"",
            esp3:"",
            esp4:"",
            esp5:"",
            esp6:"",
            esp7:"",
            esp8:"",
            esp9:"",
            esp10:"",
        },
        submited:false
    }

    const [DatosFiltros, setDatosFiltros] = useState({
        marcas: [],
        datos_filtros:[],
        caracs_principales:[],
        caracs_secundarias:[]
    })
    const ResetDatosFiltros = {
        marcas: [],
        datos_filtros:[],
        caracs_principales:[],
        caracs_secundarias:[]
    }

    const [TituloSubfamilia, setTituloSubfamilia] = useState({
        nombre: "",
        cant_productos: 0
    });

    useEffect(() => {
        if (Estado === "inicio") {
            setProductos([]);
            cargarProductos();
        }
    },[Estado])

    useEffect(()=>{
        if(Estado==="cargado"){
            setEstado("inicio");
        }
    },[menu_load])


    //useEffect(()=>{
    //    cargarProductos();
    //    //console.log("Cargando Productos");
    //},[capa_cargar,superfamilia,familia,subfamilia,DatosCarga.pagina,DatosCarga.orden])

    const cargarProductos = () => {
        var capa_cargar_definitiva = "subfamilia";
        setEstado("cargando");

        if(DropMenuProductos)setDropMenuProductos(false);

        /* Comprobar capa bien aplicada */
        if(capa_cargar==="superfamilia")    capa_cargar_definitiva="superfamilia";
        else if(capa_cargar==="familia")    capa_cargar_definitiva="familia";
        else if(capa_cargar==="subfamilia") capa_cargar_definitiva="subfamilia";
        /* FIN Comprobar capa bien aplicada */

        var datos = new FormData();
        /* Comprobar cambio de subfamilia para filtros */
        /*if (subfamilia !== TituloSubfamilia.nombre) {
            setFiltros(ResetFiltros);
            datos.append("filtro_destacado", ResetFiltros.destacado);
            datos.append("filtro_oferta", ResetFiltros.oferta);
            datos.append("filtro_marca", ResetFiltros.marca);
            datos.append("filtro_carac_principal", ResetFiltros.carac_principal);
            datos.append("filtro_carac_secundaria", ResetFiltros.carac_secundaria);
            datos.append("filtros_valor", JSON.stringify(ResetFiltros.filtros_valor));
        } else {
            datos.append("filtro_destacado", Filtros.destacado);
            datos.append("filtro_oferta", Filtros.oferta);
            datos.append("filtro_marca", Filtros.marca);
            datos.append("filtro_carac_principal",Filtros.carac_principal);
            datos.append("filtro_carac_secundaria",Filtros.carac_secundaria );
            datos.append("filtros_valor", JSON.stringify(Filtros.filtros_valor));
        }*/
        /* Fin Comprobar cambio de subfamilia para filtros */
        datos.append("filtro_destacado", Filtros.destacado);
        datos.append("filtro_oferta", Filtros.oferta);
        datos.append("filtro_marca", Filtros.marca);
        datos.append("filtro_carac_principal",Filtros.carac_principal);
        datos.append("filtro_carac_secundaria",Filtros.carac_secundaria );
        datos.append("filtros_valor", JSON.stringify(Filtros.filtros_valor));

        datos.append("accion", "cargar");
        datos.append("limite_pagina", DatosCarga.limite_pagina);
        datos.append("pagina", DatosCarga.pagina);
        datos.append("orden", DatosCarga.orden);

        datos.append("superfamilia", decodeURIComponent(superfamilia));
        datos.append("familia", decodeURIComponent(familia));
        datos.append("subfamilia", decodeURIComponent(subfamilia));
        datos.append("capa_cargar", capa_cargar_definitiva);

        axios({
            url: process.env.PUBLIC_URL + "/assets/api/productos_cargar.php",
            method: "post",
            data: datos
        }).then((resp) => {
            var res = resp.data;
            //console.log(res); 
            if (res[0] === "realizado") {
                setEstado("cargado");
                setProductos(res['productos']);
                setDatosCarga(
                    prevState=>({
                        ...prevState,
                        cant_paginas:res['cant_paginas'],
                        cant_total:res['cant_total'],
                    })
                );
                setDatosFiltros({ 
                    marcas: res['filtro_marcas'],
                    datos_filtros:res['datos_filtros'],
                    caracs_principales:res['filtro_caracs_principales'],
                    caracs_secundarias:res['filtro_caracs_secundarias']
                });
                if(res['productos'][0]['superfamilia'].length>0 && res['productos'][0]['familia'].length>0 && res['productos'][0]['subfamilia'].length>0 && capa_cargar_definitiva==="subfamilia"){
                    setRutas([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: res['productos'][0]['superfamilia'], ruta: "/superfamilias/" + res['productos'][0]['superfamilia'] },
                        { nombre: res['productos'][0]['familia'] },
                        { nombre: res['productos'][0]['subfamilia'] }
                    ]);
                    setTituloSubfamilia({
                        //nombre: res['productos'][0]['subfamilia'],
                        nombre:decodeURIComponent(subfamilia),
                        cant_productos: res['cant_total']
                    });
                }else if(res['productos'][0]['superfamilia'].length>0 && res['productos'][0]['familia'].length>0 && capa_cargar_definitiva==="familia"){
                    setRutas([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: res['productos'][0]['superfamilia'], ruta: "/superfamilias/" + res['productos'][0]['superfamilia'] },
                        { nombre: res['productos'][0]['familia']}
                    ]);
                    setTituloSubfamilia({
                        //nombre: res['productos'][0]['familia'],
                        nombre:decodeURIComponent(familia),
                        cant_productos: res['cant_total']
                    });
                }else if(res['productos'][0]['superfamilia'].length>0 &&  capa_cargar_definitiva==="superfamilia"){
                    setRutas([
                        { nombre: "Inicio", ruta: "/" },
                        { nombre: res['productos'][0]['superfamilia'], ruta: "/superfamilias/" + res['productos'][0]['superfamilia'] }
                    ]);
                    setTituloSubfamilia({
                        //nombre: res['productos'][0]['superfamilia'],
                        nombre:decodeURIComponent(superfamilia),
                        cant_productos: res['cant_total']
                    });
                }
            } else if (res[0] === "no_encontrado") {
                setEstado("cargado");
                setProductos([]);
                setDatosCarga(prevState=>({
                    ...prevState
                    ,
                    pagina: 1,
                    orden: "sin_orden",
                    cant_paginas: 1,
                    cant_total: 1,
                }));
                setDatosFiltros(ResetDatosFiltros);
                setRutas([
                    { nombre: "Inicio", ruta: "/" },
                    { nombre: "No encontrado" }
                ]);
                setTituloSubfamilia({
                    nombre: "Productos no encontrados",
                    cant_productos: 0
                });
            }
        })
    }

    const [DropMenuProductos,setDropMenuProductos] = useState(false);
    //CREAR STYLES ANIMATED
    const EstilosMenuContProductos = useSpring({
        zIndex:"1100",
        top:"0px",
        display:DropMenuProductos?"inherit":"none"
    })

    useEffect(()=>{
        if(DatosCarga?.pagina!==1) setDatosCarga(prevState=>({...prevState,pagina:1}));
    },[superfamilia, familia, subfamilia, capa_cargar,menu_load]);

    return (
        <div>
            {
                TituloSubfamilia.nombre?
                <MetaData titulo={(TituloSubfamilia.nombre.charAt(0)+TituloSubfamilia.nombre.toLowerCase().slice(1))}>
                </MetaData>:""

            }
            <Ruta className="d-none d-sm-block" ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div className="container pt-3">
                    {Estado === "inicio" || Estado === "cargando" ?

                        <LinearProgressMine></LinearProgressMine>
                        : ""
                    }
                    {
                        Estado === "cargado" ?
                            <div className="d-flex flex-row align-items-center">
                                <Typography className="text-dark" /* style={{color:EstilosGlobales.colorSecundario}} */ variant="h5">
                                    {TituloSubfamilia.nombre}
                                </Typography>
                            <Typography variant="h6" className="text-secondary ml-2">({TituloSubfamilia.cant_productos} Productos)</Typography>
                        </div>
                        :
                        <Skeleton height={41} width={300}></Skeleton>
                    }
                </div>
                <div className="container py-2">
                    
                    
                    <div className="row">
                        {size.width>767?
                            <div className="col-md-4 col-lg-3">
                                <SideBarFiltros className="mb-2" Estado={{Estado,setEstado}} DatosFiltros={{DatosFiltros,setDatosFiltros}} Filtros={{Filtros,setFiltros}} ResetFiltros={{ResetFiltros}}></SideBarFiltros>
                                <SideBar DatosCarga={DatosCarga} setDatosCarga={setDatosCarga} EstadoPadre={{Estado,setEstado}}></SideBar>
                            </div>:
                            <div className="" 
                                style={{
                                    zIndex:"900",
                                }}>
                                <Button onClick={()=>{setDropMenuProductos(true)}} className="d-flex flex-row align-items-center" size="large" variant="contained" 
                                    style={{
                                        backgroundColor:EstilosGlobales.colorSecundario,
                                        //position:parseInt(scroll.varY)>488?"fixed":"static",
                                        top:"5px"
                                    }}
                                >
                                    <ListIcon style={{color:EstilosGlobales.colorPrimario}}/>     
                                    <Typography className="ml-2 font-weight-bold text-white" variant="body2">
                                        Productos y Filtros                                     
                                    </Typography>
                                </Button>
                                <animated.div className="MENUProductosFiltroContainer position-fixed w-100 h-100" style={EstilosMenuContProductos}>
                                    <div className="BACKGROUND_MenuProductosFiltro w-100" style={{zIndex:"1101",backgroundColor:EstilosGlobales.colorSecundario,opacity:"0.3",height:size.height}}>
                                    </div>
                                        <div className="position-absolute bg-light" style={{width:"260px",zIndex:"1102",top:"0px",left:"0px",height:size.height,overflow:"scroll"}}>
                                        <div className="text-right">
                                            <Button onClick={()=>{setDropMenuProductos(false);}} variant="contained" className="font-weight-bold px-0 py-1 text-white" style={{backgroundColor:EstilosGlobales.colorSecundario}}>X</Button>
                                            <SideBarFiltros className="mb-2" Estado={{Estado,setEstado}} DatosFiltros={{DatosFiltros,setDatosFiltros}} Filtros={{Filtros,setFiltros}} ResetFiltros={{ResetFiltros}}></SideBarFiltros>
                                            <SideBar DatosCarga={DatosCarga} setDatosCarga={setDatosCarga} EstadoPadre={{Estado,setEstado}}></SideBar>
                                        </div>
                                    </div>
                                </animated.div>
                            </div>
                        }
                        <ProductosCatalogo EstadoPadre={{Estado,setEstado}} Productos={Productos} DatosCarga={DatosCarga} setDatosCarga={setDatosCarga} DatosFiltros={{DatosFiltros,setDatosFiltros}} Filtros={{Filtros,setFiltros}} cargarProductos={{cargarProductos}} className="col-md-8 col-lg-9">                            
                        </ProductosCatalogo>
                    </div>
                </div>
            </div>
        </div>
    );
}
