import React, { useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import number_format from '../context/numberFormat'
import Swal from '../context/SwalAlert'
import { Link,Redirect } from 'react-router-dom';
import axios from 'axios';
import LinearProgressMine from '../components/LinearProgressMine'
import EstilosGlobales from '../toremp/EstilosGlobales'

import Carro from '../context/Carro'
import CapitalizarTexto from '../context/CapitalizarTexto'

//Favorites Recursos
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import UsuarioClienteContext from '../context/UsuarioClienteContext'

import InboxIcon from '@material-ui/icons/Inbox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DashboardIcon from '@material-ui/icons/Dashboard';

//TOREMP
import ConfigModulos from '../toremp/ConfigModulos'

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 220,
    },
});

export default function Producto(props) {
    const classes = useStyles();
    /*const [DatosProducto,setDatosProducto] =useState({
        SKU:"-",
        marca:"-",
        nombre:"-",
        precio:0,
        oferta:"",
        precio_oferta:0,
        destacado:0,
        stock_total:0,
        img_01:"#",
        cuenta:null
    });*/

    const [DatosProducto,setDatosProducto] = useState(props.DatosProducto);

    /*useEffect(()=>{
        setDatosProducto(props.DatosProducto);
    },[])*/

    const [RedirigirCarro,setRedirigirCarro] = useState(false);

    /* CartDN */
    const {ActualizarCarro} = useContext(Carro);
    const [EstadoAgregarCarro,setEstadoAgregarCarro] = useState("cargado");
    const AgregarCarro=()=>{
        if(DatosProducto.cotizar && parseInt(DatosProducto.cotizar)>0){
            Swal.fire({
                title:"Agregar Carro",
                html:"El Producto no puede ser agregado al carro, ya que esta en modo cotizar.",
                icon:"warning",
                confirmButtonText:"Aceptar",
                confirmButtonColor:EstilosGlobales.colorSecundario,
                position: 'top-end'
            })
            return false;
        }
        if(EstadoAgregarCarro==="cargando"){
            return false;
        }
        setEstadoAgregarCarro("cargando");
        var SKU = DatosProducto.SKU;
        var cantidad = 1;
        var datos = new FormData();

        datos.append("SKU",SKU);
        datos.append("cantidad",cantidad);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_agregar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            var html_body = "";
            if(res[0]==="realizado"){
                html_body = <div>El producto fue agregado <b>Satisfactoriamente al carro de compras</b>.</div>;
                Swal.fire({
                    title:"Producto Agregado",
                    html:html_body,
                    icon:"success",
                    showCancelButton:true,
                    cancelButtonText:"Aceptar",
                    cancelButtonColor:EstilosGlobales.colorPrimario,
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    position: 'top-end',
                    confirmButtonText:"Ver Carro"
                    
                }).then((result)=>{
                    if(result.value){
                        setRedirigirCarro(true);
                    }
                })
                ActualizarCarro();
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="existe"){
                html_body = 
                <div>
                    El producto <b>ya se encuentra en el carro de compras.</b>
                </div>;
                Swal.fire({
                    title:"Producto en Carro",
                    html:html_body,
                    icon:"info",
                    showCancelButton:true,
                    cancelButtonText:"Aceptar",
                    cancelButtonColor:EstilosGlobales.colorPrimario,
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Ver Carro",
                    position: 'top-end'
                }).then((result)=>{
                    if(result.value){
                        setRedirigirCarro(true);
                    }
                })
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="no_existe"){
                html_body = 
                <div>
                    El producto <b>no existe en la base de datos.</b><br></br><br></br>
                    Recargue e intente nuevamente, si el problema persiste contáctese con soporte.
                </div>;
                Swal.fire({
                    title:"Producto no existe",
                    html:html_body,
                    icon:"error",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="error_stock"){
                html_body = 
                <div>
                    La cantidad supera el <b>Stock</b> disponible.
                    {ConfigModulos.CarroCompras.agregar_carro.mensajes.sin_stock.html}
                </div>;
                Swal.fire({
                    title:"Agregar Producto",
                    html:html_body,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar",
                    position: 'top-end'
                })
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="error_limitecompra"){
                html_body = 
                <div>
                    La cantidad supera el <b>limite máximo de unidades a comprar</b> de este producto.
                </div>;
                Swal.fire({
                    title:"Agregar Producto",
                    html:html_body,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar",
                    position: 'top-end'
                })
                setEstadoAgregarCarro("cargado");
            }
        })
    }
    /* FNCartDN */

    

    /* INI FAVORITOS */
    const {IsLoged} = useContext(UsuarioClienteContext);
    const [SubmitFavorito,setSubmitFavorito] = useState(false);
    const handleFavorito=()=>{
        if(SubmitFavorito) {
            return false;
        }
        if(!IsLoged){
            var body_html=
                <div className="">
                    <Typography>Para ingresar un favorito debe logearse</Typography>    
                </div>
            ;
            Swal.fire({
                title:"Añadir Favorito",
                html:body_html,
                confirmButtonColor:EstilosGlobales.colorPrimario,
                confirmButtonText:"Aceptar",
                icon:"info"
            })
            return false;
        }

        setSubmitFavorito(true);
        var accion_fav;
        
        if(DatosProducto.cuenta!==null && DatosProducto.cuenta){
            accion_fav="del";
        }else{
            accion_fav="add";
        }

        var datos = new FormData();
        datos.append("SKU",DatosProducto.SKU);
        datos.append("accion_fav",accion_fav);
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/producto_add_fav.php" ,
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            var body_html;
            if(res[0]==="realizado"){
                if(accion_fav==="add"){
                    body_html=
                    <Typography>
                        Producto <b>{DatosProducto.nombre}</b> fue <b className="text-success">agregado</b> a tus favoritos
                    </Typography>
                    ;
                    setDatosProducto({...DatosProducto,cuenta:res['cuenta']});
                }else if(accion_fav==="del"){
                    body_html=
                    <Typography>
                        Producto <b>{DatosProducto.nombre}</b> fue <b className="text-danger">borrado</b> de tus favoritos
                    </Typography>
                    ;
                    setDatosProducto({...DatosProducto,cuenta:null});
                } 
                Swal.fire({
                    title:"Favoritos",
                    icon:"success",
                    html:body_html,
                    confirmButtonText:"Aceptar",
                    confirmButtonColor:EstilosGlobales.colorPrimario
                })
                setSubmitFavorito(false);
                
            }else{
                Swal.fire({
                    html:"Ocurrió un error inesperado al realizar la operación. Recargue e intente nuevamente. Si el error persiste, contacte a soporte.",
                    title:"Error Inesperado",
                    icon:"warning"
                }) 
            }
        })
        
    }
    /* FN FAVORITOS */

    return (
        <div className={props.className}>
            {
                RedirigirCarro?
                <Redirect to="/carro"></Redirect>:""
            }
            <Card className={classes.root+" position-relative"}>
                <Link className="text-decoration-none" to={"/producto/"+DatosProducto.SKU}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={process.env.PUBLIC_URL+"/assets/imagenes/productos/"+DatosProducto.img_01}
                            title="Contemplative Reptile"
                        />                    
                        <CardContent className="pb-0">
                            <Typography className="text-left" style={{color:"#000000"}} variant="caption" component="h2">
                                <b style={{color:EstilosGlobales.colorPrimario}}>SKU:</b> <br/>{DatosProducto.SKU}
                            </Typography>
                            <Typography className="text-secondary" variant="caption" component="h2">
                                {DatosProducto.marca}
                            </Typography>
                            <Typography gutterBottom variant="body1" component="h2" style={{minHeight:"3em",lineHeight:"1.2em",color:EstilosGlobales.colorSecundario}}>
                                {DatosProducto.nombre}                        
                            </Typography>
                            {
                                DatosProducto.cotizar && parseInt(DatosProducto.cotizar)<=0?
                                <>
                                    {
                                        DatosProducto.precio_retail>DatosProducto.precio?
                                        <div className="d-flex flex-row align-items-center">
                                            <Typography className="font-weight-bold text-secondary" variant="body2">
                                                $ {number_format(DatosProducto.precio_retail,0,",",".")}                                     
                                            </Typography>
                                            <Typography className="font-weight-bold text-secondary ml-1" variant="caption"> 
                                                - P. Normal 
                                            </Typography> 
                                        </div>:
                                        <div className="pt-2 pb-1">
                                        </div>
                                    }
                                    {

                                        DatosProducto.oferta>0?
                                        <div className="d-flex flex-row align-items-center">
                                            <Typography className="font-weight-bold" variant="h6" style={{color:EstilosGlobales.colorSecundario}} component="p">
                                                $ {number_format(DatosProducto.precio_oferta,0,",",".")} 
                                            </Typography>
                                            <Typography variant="body2" className="text-secondary ml-2" style={{textDecoration:"line-through"}}>
                                                $ {number_format(DatosProducto.precio,0,",",".")} 
                                            </Typography>
                                        </div>
                                        :
                                        <Typography className="font-weight-bold" variant="h6" style={{color:EstilosGlobales.colorSecundario}} component="p">
                                            $ {number_format(DatosProducto.precio,0,",",".")}                 
                                        </Typography>
                                    }
                                </>:
                                <Typography className="font-weight-bold" variant="h6" style={{color:EstilosGlobales.colorSecundario}} component="p">
                                    $ Cotizar                                
                                </Typography>
                            }
                        </CardContent>
                    </CardActionArea>
                </Link>
                <div className="px-3 d-flex flex-column">
                    <div className="d-flex flex-row align-items-center">
                        <InboxIcon fontSize="small" className="text-dark" style={{color:EstilosGlobales.colorSecundario}}></InboxIcon>
                        <Typography variant="caption" className="ml-1">
                            {
                                DatosProducto.retirable>0?
                                <b className="text-success">Retiro Disponible</b>:
                                <b className="text-danger">Retiro No Disponible</b>
                            } 
                        </Typography>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <LocalShippingIcon fontSize="small" className="text-dark" style={{color:EstilosGlobales.colorSecundario}}></LocalShippingIcon>
                        <Typography variant="caption" className="ml-1">
                            {
                                DatosProducto.despachable>0 && DatosProducto.var_peso>0?
                                <b className="text-success">Despacho Disponible</b>:
                                <b className="text-danger">Despacho No Disponible</b>
                            } 
                        </Typography>
                    </div>
                    {
                        DatosProducto.cotizar>0?
                        <div className="d-flex flex-row align-items-center">
                            <DashboardIcon fontSize="small" className="text-dark" style={{color:EstilosGlobales.colorSecundario}}></DashboardIcon>
                            <Typography variant="caption" className="ml-1 font-weight-bold text-secondary">
                                Consultar disponibilidad
                            </Typography>
                        </div>:""

                    }
                </div>
                <CardActions>
                    {
                        DatosProducto.cotizar && parseInt(DatosProducto.cotizar)<=0?
                        <div className="d-flex flex-row justify-content-center w-100">
                            <Button onClick={AgregarCarro} className="text-white" size="small" variant="contained" style={{backgroundColor:EstilosGlobales.colorSecundario}}>
                                Agregar
                            </Button>                                        
                        </div>:
                        <Link to={"/producto/"+DatosProducto.SKU} className="d-flex flex-row justify-content-center w-100 text-decoration-none">
                            <Button className="text-white" size="small" variant="contained" style={{backgroundColor:EstilosGlobales.colorSecundario}}>
                               Ver 
                            </Button>                                        
                        </Link>
                    }
                </CardActions>
                
                {
                    EstadoAgregarCarro==="cargando"?
                    <LinearProgressMine></LinearProgressMine>
                    :""
                }
                
                {
                    DatosProducto.destacado>0?
                    <div className="bg-dark position-absolute px-4 pb-1" style={{top:"0px",left:"0px"}}>
                        <Typography className="text-white font-weight-bold" variant="caption">Destacado</Typography>
                    </div>
                    :""
                }
                {
                    DatosProducto.oferta>0?
                    <div className="position-absolute px-4 pb-1" style={{top:"0px",right:"0px",backgroundColor:EstilosGlobales.colorPrimario}}>
                        <Typography className="text-white font-weight-bold" variant="caption">Oferta</Typography>
                    </div>
                    :""
                }
                {
                    DatosProducto.stock_total<=0 && parseInt(DatosProducto.cotizar)<=0?
                    <div className="position-absolute w-100 py-1" style={{top:"30%",zIndex:"20",backgroundColor:EstilosGlobales.colorPrimario}}>
                        <Typography className="font-weight-bold text-white text-center" variant="body2">STOCK NO DISPONIBLE</Typography>
                    </div>:""
                }
                {
                    
                }
                <div onClick={handleFavorito} className="rounded-circle p-1 position-absolute bg-light" style={{bottom:"5px",right:"5px",cursor:"pointer"}}>
                    {
                        DatosProducto.cuenta!==null && DatosProducto.cuenta?
                        <FavoriteIcon style={{color:EstilosGlobales.colorPrimario}}/>
                        :
                        <FavoriteBorderIcon className="text-secondary"/>
                    }
                </div>
            </Card>
        </div>
    );
}
