import {useState,useEffect} from 'react'
import {Typography} from '@material-ui/core'
import EstilosGlobales from '../EstilosGlobales.jsx'
import CapitalizarTexto from '../../context/CapitalizarTexto.jsx'

export default function TituloTorEmp(props){
    var sizeTexto = "";
    const pxAncho = "120px";
    const altoCuadro = "7px";
    const bottomParam = "-3.5px";
    if(props.size && props.size==="small"){
        sizeTexto="h5";
    }else if(props.size && props.size==="xsmall"){
        sizeTexto="h6";
    }else{
        sizeTexto="h4"
    }

    const [tituloFinal,settituloFinal] = useState();

    useEffect(()=>{
        if(props.titulo){
            settituloFinal(props.titulo);
        }else if(props.children){
            settituloFinal(props.children);
        }
    },[props.titulo,props.children])

    return (
        <div className={props.className}>
            <div className="row" style={{marginLeft:'0px',marginRight:'0px'}}>
                {
                    tituloFinal && tituloFinal.length>0?
                    <Typography className="font-toremp" variant={sizeTexto}>{CapitalizarTexto(tituloFinal)}</Typography>
                    :""
                }
            </div>
            <div className="row position-relative mt-1" style={{marginLeft:'0px',marginRight:'0px'}}>
                <div className="border-bottom position-relative" style={{ width: "100%", height: "1px",color:EstilosGlobales.colorSecundario }}>

                    </div>
                <div className="position-absolute" style={{ width: pxAncho, height: altoCuadro, left: "0px", backgroundColor: EstilosGlobales.colorPrimario, bottom: bottomParam }}>
                </div>
                    <div className="position-absolute" style={{ width: pxAncho, height: altoCuadro, backgroundColor: EstilosGlobales.colorSecundario, bottom: bottomParam,left:pxAncho }}>

                </div>
            </div>
        </div>
    );
}
