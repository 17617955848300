import {useContext} from 'react'
import DatosConfigContext from '../../../context/config.jsx'
import {Typography} from '@material-ui/core'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EstilosGlobales from '../../../toremp/EstilosGlobales.jsx'
import {Link} from 'react-router-dom'

export default function MenuSucursales(){

    const {DatosConfig} = useContext(DatosConfigContext);

    return(
        <div className="bg-white pb-1 d-none d-md-block">
            <div className="container py-0">
                <div className="row justify-content-center">
                    {
                        DatosConfig.Empresa.sucursales.map((sucursal,index)=>{
                            if(DatosConfig.Empresa.sucursales[index+1]){
                                return(
                                    <Sucursal key={index} separador={true}  nombre={sucursal.nombre} telefono={sucursal.telefono}/>
                                )
                            }else{
                                return(
                                    <Sucursal separador={false} key={index} nombre={sucursal.nombre} telefono={sucursal.telefono}/>
                                ) 
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}

function Sucursal(props){
    let {nombre,telefono,separador} = props;
    return(
    <>
        <div className="text-center">
            <Link to="/contacto/sucursales" className="d-flex flex-row align-items-center py-0 my-0 text-decoration-none">
                <LocationOnIcon fontSize="small" style={{color:EstilosGlobales.colorSecundario}} />
                <Typography className="py-0 my-0 font-weight-bold" variant="caption" style={{lineHeight:"0px"}}>
                    {nombre}
                </Typography>
            </Link>
            <div className="py-0 d-flex flex-column">
            <a className="text-decoration-none text-dark" href={"tel:"+telefono}>
                <Typography className="py-0 my-0" variant="caption" style={{lineHeight:"0px"}}>
                    {telefono[0]}
                </Typography>
            </a>
            </div>
        </div> 
        {
           separador? 
            <Separador>
            </Separador>:""
        }
    </>
    );
}

function Separador(){
    return(
        <div className="border-left mx-3">
        </div>
    )
}
