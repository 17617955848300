import {useEffect,useState} from 'react'
import axios from 'axios'

export default function useConfigEtiqueta(){
    const [ConfigEtiqueta,setConfigEtiqueta] = useState("categorias");
    const [Cargado,setCargado] = useState(false);

    useEffect(()=>{
        const cargarConfigEtiqueta=()=>{
            var datos = new FormData();
            datos.append("accion","cargar_configuracion_etiqueta");
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/configuracion_etiqueta.php",
                method:"post",
                data:datos
            }).then((resp)=>{
                var res = resp.data;
                //console.log(res);
                if(res[0]==="realizado"){
                    setConfigEtiqueta(res[1]);
                    setCargado(true);
                }
            })
        }

        cargarConfigEtiqueta();
    },[])

    if(Cargado)return ConfigEtiqueta;
} 
