import {useContext,useEffect} from 'react'
import {Typography} from '@material-ui/core'
import EstilosGlobales from '../../toremp/EstilosGlobales'
import DatosConfigContext from '../../context/config'
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function MenuPersonalizadoTorEmp(){
    const {DatosConfig} = useContext(DatosConfigContext);

    useEffect(()=>{
        //console.log(DatosConfig.Empresa);
    },[])

    return(
        <div>
            {DatosConfig && DatosConfig.Empresa.sucursales?
            <div className="row justify-content-center">
                {
                    DatosConfig.Empresa.sucursales.map((sucursal,index)=>{
                        return(
                            <div className="col-md-3 col-6 px-2 my-1" key={index}>
                                <div className="px-2 text-center text-white">
                                    <Typography className="font-weight-bold mb-0 pb-0">
                                        {sucursal.nombre}
                                    </Typography>
                                    {
                                        sucursal.direccion && sucursal.direccion.length>0?
                                        <div className="font-weight-bold py-0 my-0">
                                            <LocationOnIcon style={{fontSize:"1rem",color:EstilosGlobales.colorPrimario}}/>
                                            <Typography variant="caption" className="text-white">
                                                {sucursal.direccion}
                                            </Typography>
                                        </div>:""
                                    }
                                    <div className="font-weight-bold py-0 my-0">
                                        <PhoneIcon style={{fontSize:"1rem",color:EstilosGlobales.colorPrimario}}/>
                                        <Typography variant="caption" className="text-white">
                                            {sucursal.telefono}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>:""
            }
        </div>
    );
}
