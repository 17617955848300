import { useParams } from 'react-router';
import Ruta from '../Ruta'
import {useEffect, useState, useContext} from 'react'
import Titulo from '../Titulo'
import { Card, CardActionArea,Typography } from '@material-ui/core';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Producto from '../Producto'
import { Pagination, Skeleton } from '@material-ui/lab';
import Capitalizar from '../../context/CapitalizarTexto'
import EstilosGlobales from '../../toremp/EstilosGlobales';
//import SideBarFiltros from '../productos/SideBarFiltros';

//META
import DatosConfigContext from '../../context/config.jsx'
import {Helmet} from 'react-helmet-async'



export default function ContenidoBuscar(){

    let {busqueda} = useParams();
    
    const [Buscar,setBuscar] = useState(decodeURIComponent(busqueda));
    

    //const [Superfamilias,setSuperfamilias] = useState([]);
    const [Subfamilias,setSubfamilias] = useState([]);
    const [Productos,setProductos] = useState([]);
    const [Estado,setEstado] = useState("inicio");
    const [DatosCarga,setDatosCarga] = useState({
        limite_pagina:16,
        pagina:1,        
        cant_paginas:1,
        cant_total:1        
    })

    const [Filtros, setFiltros] = useState({
        destacado: false,
        oferta: false,
        marca: "",
        carac_principal:"",
        carac_secundaria:"",
        submited:false
    })
    const ResetFiltros = {
        destacado: false,
        oferta: false,
        marca: "",
        carac_principal:"",
        carac_secundaria:"",
        submited:false
    }

    /*const [DatosFiltros, setDatosFiltros] = useState({
        marcas: [],
        caracs_principales:[],
        caracs_secundarias:[]
    })
    const ResetDatosFiltros = {
        marcas: [],
        caracs_principales:[],
        caracs_secundarias:[]
    }*/

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarBusqueda();
        }else if(Estado==="cambio_pagina"){
            cambioPagina();
        }
        if(decodeURIComponent(busqueda)!==Buscar){
            cargarBusqueda();
            
        }
        
    })

    const cargarBusqueda=()=>{        
        setEstado("cargando");
        setBuscar(decodeURIComponent(busqueda)); 
 
        var datos = new FormData();
        datos.append("accion","buscar");
        datos.append("busqueda",decodeURIComponent(busqueda));
        datos.append("limite_pagina",DatosCarga.limite_pagina);
        datos.append("pagina",1);        
                        
        /* Comprobar cambio de subfamilia para filtros */
        if(decodeURIComponent(busqueda)!==Buscar){
            setFiltros(ResetFiltros);
            datos.append("filtro_destacado", ResetFiltros.destacado);
            datos.append("filtro_oferta", ResetFiltros.oferta);
            datos.append("filtro_marca", ResetFiltros.marca);
            datos.append("filtro_carac_principal", ResetFiltros.carac_principal);
            datos.append("filtro_carac_secundaria", ResetFiltros.carac_secundaria);
        } else {
            datos.append("filtro_destacado", Filtros.destacado);
            datos.append("filtro_oferta", Filtros.oferta);
            datos.append("filtro_marca", Filtros.marca);
            datos.append("filtro_carac_principal",Filtros.carac_principal);
            datos.append("filtro_carac_secundaria",Filtros.carac_secundaria);
        }
        /* Fin Comprobar cambio de subfamilia para filtros */
           
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/buscar_resultados.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            
            if(res[0]==="realizado"){
                //setSuperfamilias(res['superfamilias']);
                setSubfamilias(res['subfamilias']);
                setProductos(res['productos']);
                if(res['cant_total']>0){
                    setDatosCarga({
                        ...DatosCarga,
                        cant_paginas:res['cant_paginas'],
                        cant_total:res['cant_total'],
                        pagina:1  
                    })
                }else{
                    setDatosCarga({
                        ...DatosCarga,
                        cant_paginas:1,
                        cant_total:0 ,
                        pagina:1   
                    })
                }
                /*setDatosFiltros({
                    marcas:res['filtro_marcas'],
                    caracs_principales:res['filtro_caracs_principales'],
                    caracs_secundarias:res['filtro_caracs_secundarias']
                })*/
                setEstado("cargado");
            }else if(res[0]==="no_encontrado"){
                //setSuperfamilias([]);
                setSubfamilias([]);
                setProductos([]);
                setDatosCarga({
                    ...DatosCarga,
                    cant_paginas:1,
                    cant_total:0,
                    pagina:1   
                })
                //setDatosFiltros(ResetDatosFiltros);
                setEstado("cargado");
            }
        })
                
    }
    const cambioPagina=()=>{
    
        setEstado("cargando");
        setBuscar(decodeURIComponent(busqueda));
        var datos = new FormData();
        datos.append("accion","buscar");
        datos.append("busqueda",decodeURIComponent(busqueda));
        datos.append("limite_pagina",DatosCarga.limite_pagina);
        datos.append("pagina",DatosCarga.pagina);        

        /* Comprobar cambio de subfamilia para filtros */
        if(decodeURIComponent(busqueda)!==Buscar){
            setFiltros(ResetFiltros);
            datos.append("filtro_destacado", ResetFiltros.destacado);
            datos.append("filtro_oferta", ResetFiltros.oferta);
            datos.append("filtro_marca", ResetFiltros.marca);
            datos.append("filtro_carac_principal", ResetFiltros.carac_principal);
            datos.append("filtro_carac_secundaria", ResetFiltros.carac_secundaria);
        } else {
            datos.append("filtro_destacado", Filtros.destacado);
            datos.append("filtro_oferta", Filtros.oferta);
            datos.append("filtro_marca", Filtros.marca);
            datos.append("filtro_carac_principal",Filtros.carac_principal);
            datos.append("filtro_carac_secundaria",Filtros.carac_secundaria);
        }
        /* Fin Comprobar cambio de subfamilia para filtros */
        
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/buscar_resultados.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            if(res[0]==="realizado"){                
                setProductos(res['productos']);
                setEstado("cargado");
            }else if(res[0]==="no_encontrado"){                
                setProductos([]);
                setEstado("cargado");
                setDatosCarga({
                    ...DatosCarga,
                    cant_paginas:1,
                    cant_total:0,
                    pagina:1   
                })
            }
        })
                
    }

    //META DATA
    const {DatosConfig} = useContext(DatosConfigContext);
    //FIN META DATA
    
    //const ClaseSuperfamilia="col-6 col-md-4 col-lg-4 col-xl-3 my-2";
    const ClaseProducto="px-1 px-sm-2 col-6 col-md-4 my-2";
    return(
        <div>
            {
                DatosConfig?
                <Helmet>
                    <title>
                        {`Buscar Productos - ${DatosConfig.Empresa.nombre}`}
                    </title>
                </Helmet>:""
            }
            <Ruta 
                ruta={[
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:"Buscando: "+decodeURIComponent(busqueda)}
                ]}
            >
            
            </Ruta>
            <div className="bg-light">
                
                <div className="container py-2">
                    <div className="row justify-content-center">
                        <Typography variant="h5"><b>Buscando:</b> {decodeURIComponent(busqueda)}</Typography>
                    </div>
                    {/*
                        <Titulo titulo="Categorias Encontradas"></Titulo>
                        {
                            Estado==="cargado"?
                            <div className="row">
                                {
                                    Superfamilias.map((superfamilia,index)=>{
                                        return(
                                            <div key={index} className={ClaseSuperfamilia}>
                                                <Link className="text-decoration-none" to={"/superfamilias/"+superfamilia.superfamilia}>
                                                    <Card>
                                                        <CardActionArea className="px-2 px-sm-3 px-md-5 py-2">
                                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                                
                                                                <img className="img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/superfamilias/"+superfamilia.imagen} alt={superfamilia.superfamilia}></img>
                                                                
                                                                <Typography style={{color:EstilosGlobales.colorPrimario,minHeight:"3em"}} className="text-center font-weight-bold">{superfamilia.superfamilia}</Typography>
                                                                
                                                            </div>
                                                        </CardActionArea>
                                                    </Card>  
                                                </Link> 
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            :
                            <div className="row">
                                <div className={ClaseSuperfamilia}>
                                    <Skeleton height={223} variant="rect"></Skeleton>                                
                                </div>
                                <div className={ClaseSuperfamilia}>
                                    <Skeleton height={223} variant="rect"></Skeleton>                                
                                </div>
                                <div className={ClaseSuperfamilia}>
                                    <Skeleton height={223} variant="rect"></Skeleton>                                
                                </div>
                                <div className={ClaseSuperfamilia}>
                                    <Skeleton height={223} variant="rect"></Skeleton>                                
                                </div>
                            </div>
                        }
                    */}
                    <Titulo className="mt-4">Subcategorias Encontradas</Titulo>
                    <div className="row">
                        {
                            Subfamilias.map((subfamilia,index)=>{
                                return(
                                    <div key={index} className="col-md-3 col-sm-6 col-6 mt-3">
                                        <Link className="text-decoration-none" to={"/productos/"+encodeURIComponent(subfamilia.superfamilia)+"/"+encodeURIComponent(subfamilia.familia)+"/"+encodeURIComponent(subfamilia.subfamilia)+"/subfamilia"}>
                                            <Card>
                                                <CardActionArea>
                                                    <div className="cont_img overflow-hidden position-relative" style={{height:"200px"}}>
                                                        <img src={process.env.PUBLIC_URL+"/assets/imagenes/productos/"+subfamilia.imagen} alt="" className="img-fluid position-relative" style={{top:"50%",transform:"translate(0px,-50%)"}}/>
                                                    </div>
                                                </CardActionArea>
                                                <Typography style={{backgroundColor:EstilosGlobales.colorSecundario}} className="text-center text-white py-1 font-weight-bold">
                                                    {Capitalizar(subfamilia.subfamilia)}
                                                </Typography>
                                            </Card>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container py-4">
                    <Titulo>Productos Encontrados</Titulo>
                    {
                        Estado==="cargado" || Estado==="cambio_pagina"?
                        <div className="row">
                            <div className="col-md-3 mt-2">
                                {/*<SideBarFiltros className="mb-2" Estado={{Estado,setEstado}} DatosFiltros={{DatosFiltros,setDatosFiltros}} Filtros={{Filtros,setFiltros}} ResetFiltros={{ResetFiltros}} ></SideBarFiltros>*/}
                            </div>
                            {/*<div className="col-md-9">*/}
                            <div className="col-md-12">
                                <div className="row">
                                    {
                                        Productos.map((producto, index) => {
                                            return (
                                                <Producto key={index} DatosProducto={producto} className={ClaseProducto}></Producto>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className={ClaseProducto}>
                                <Skeleton height={405} variant="rect"></Skeleton>                                
                            </div>
                            <div className={ClaseProducto}>
                                <Skeleton height={405} variant="rect"></Skeleton>                                
                            </div>
                            <div className={ClaseProducto}>
                                <Skeleton height={405} variant="rect"></Skeleton>                                
                            </div>
                            <div className={ClaseProducto}>
                                <Skeleton height={405} variant="rect"></Skeleton>                                
                            </div>
                        </div>
                    }
                    <div className="row justify-content-center py-2">
                        
                        <Pagination
                            onChange={(e, pag_dest) => {
                                if(DatosCarga.cant_paginas<=1){
                                    return false;
                                }
                                if(DatosCarga.cant_total>0){
                                    setDatosCarga({ ...DatosCarga, pagina: pag_dest });
                                    setEstado("cambio_pagina");
                                }                                
                            }}
                            count={DatosCarga.cant_paginas} color="primary"
                            page={DatosCarga.pagina} 
                            defaultPage={1}   
                        >
                            
                        </Pagination>
                    
                    </div>
                </div>
            </div>
        </div>
    );
}

