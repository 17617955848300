import React,{ useState, useEffect,useContext,useCallback } from "react";
import { Button, Card, TextField, Typography } from "@material-ui/core";
import EstilosGlobales from "../../toremp/EstilosGlobales";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import LinearProgressMine from "../LinearProgressMine";
import axios from 'axios'
import UsuarioClienteContext from '../../context/UsuarioClienteContext'
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


function ErrorPrintConsole(props){
    return(
        <div className="flex-row d-flex justify-content-center mt-2">
            <Alert severity="error">{props.children}</Alert>
        </div>
    )
}

function ContenidoLogin({gtoken,handleReCaptchaVerify}) {
    const {setIsLoged,setDatosUsuarioCliente}=useContext(UsuarioClienteContext);

    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Login" }];
    const ClaseInputs = "col-md-6";
    const [DatosLogin, setDatosLogin] = useState({
        email: "",
        password: "",
    });

    const [ErrorCampo, setErrorCampo] = useState({
        email: { error: false, texto: "" },
        password: { error: false, texto: "" },
        gcaptcha: { error: false, texto: "" },
    });

    const [Submited, setSubmited] = useState(false);
    const [PrintConsole, setPrintConsole] = useState();
    
    //Captcha INI
    /*const [GCaptchaConfig,setGCaptchaConfig] = useState({
        cargado:false,
        gsite_key:""
    });*/
    /*useEffect(()=>{
        const cargarCaptcha=()=>{
            axios({
                url: process.env.PUBLIC_URL+"/assets/api/googleCaptcha/cargar_captcha.php" 
            }).then((resp)=>{
                var res = resp.data;
                if(res[0]==="realizado"){
                    setGCaptchaConfig({
                        cargado:true,
                        gsite_key:res['gsite_key'],
                    })
                }
            })
        }

        cargarCaptcha();
    },[])*/


    const handleSubmit = async(e) => {
        e.preventDefault();
        if (Submited) {
            return false;
        }
        var MsjeCampoReq = "Campo Requerido";
        var campos_requeridos = 0;

        var ErrorCampoPre = {
            email: { error: false, texto: "" },
            password: { error: false, texto: "" },
            gcaptcha: { error: false, texto: "" }
        };

        if (DatosLogin.email.length <= 0) {
            ErrorCampoPre.email.error = true;
            ErrorCampoPre.email.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.email.error = false;

        if (DatosLogin.password.length <= 0) {
            ErrorCampoPre.password.error = true;
            ErrorCampoPre.password.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.password.error = false;

        if(gtoken.length<=0){
            ErrorCampoPre.gcaptcha.error = true;
            ErrorCampoPre.gcaptcha.texto = "Complete el Captcha";
            campos_requeridos+=1;
        } else ErrorCampoPre.gcaptcha.error=false;

        setErrorCampo(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        } else {
            setSubmited(true);
            setPrintConsole(
                <div className="pt-1">
                    <LinearProgressMine />
                    <Typography>Cargado...</Typography>
                </div>
            );
        }


        //Capa GCaptcha
        await handleReCaptchaVerify();
        var datos = new FormData();
        datos.append("g-recaptcha-response",gtoken);
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/googleCaptcha/validar_captcha.php" ,
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){

                /* CAPA Login */
                var datos = new FormData();
                datos.append("email",DatosLogin.email);
                datos.append("password",DatosLogin.password);
                axios({
                    url: process.env.PUBLIC_URL+"/assets/api/cuentas/login.php",
                    method:"post",
                    data:datos
                }).then((resp)=>{
                    var res = resp.data;
                    //console.log("Res Login:");
                    //console.log(res);
                    if(res[0]==="realizado"){
                        //alert("Submit");
                        setIsLoged(true);
                        setDatosUsuarioCliente({nombre:res['nombre']});
                    }else if(res[0]==="no_activada"){
                        setPrintConsole(
                            <ErrorPrintConsole>La cuenta aún no ha sido activada. <Link to="/activarcuenta">Reiniciar proceso de activación.</Link></ErrorPrintConsole>
                        );
                        setSubmited(false);
                        //recaptchaRef.current.reset();
                    }else if(res[0]==="error_identificacion"){
                        setSubmited(false);
                        setPrintConsole(
                            <ErrorPrintConsole>Datos de identificación <b>incorrectos.</b></ErrorPrintConsole>
                        );
                        //recaptchaRef.current.reset();
                    }else if(res[0]==="no_existe"){
                        setSubmited(false);
                        setPrintConsole(
                            <ErrorPrintConsole>La cuenta ingresada <b>no existe.</b></ErrorPrintConsole>
                        );
                        //recaptchaRef.current.reset();
                    }else{
                        
                    }
                })
                /* FIN CAPA Login */

            }else if(res[0]==="error_rechazado"){
                //recaptchaRef.current.reset();
                setErrorCampo({...ErrorCampo,gcaptcha:{error:true,texto:"Captcha inválido, reingrese."}})
            }
        }) 


    };


    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo titulo="Login"></Titulo>
                    <div className="row py-4 justify-content-center">
                        <div className="col-md-8 col-sm-10 col-12">
                            <Card className="py-4 px-2 text-center">
                                <Titulo className="px-5 mb-4">
                                    Ingresar a mi cuenta
                                </Titulo>
                                <form
                                    onSubmit={(e) => {
                                        handleSubmit(e);
                                    }}
                                >
                                    <div className="row mb-3 justify-content-center">
                                        <div className={ClaseInputs}>
                                            <TextField
                                                type="email"
                                                label="Email"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                    setDatosLogin({
                                                        ...DatosLogin,
                                                        email: e.target.value,
                                                    });
                                                }}
                                                error={ErrorCampo.email.error}
                                                helperText={
                                                    ErrorCampo.email.texto
                                                }
                                            ></TextField>
                                        </div>
                                    </div>
                                    <div className="row my-1 justify-content-center">
                                        <div className={ClaseInputs}>
                                            <TextField
                                                type="password"
                                                label="Password"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                    setDatosLogin({
                                                        ...DatosLogin,
                                                        password:
                                                            e.target.value,
                                                    });
                                                }}
                                                error={
                                                    ErrorCampo.password.error
                                                }
                                                helperText={
                                                    ErrorCampo.password.texto
                                                }
                                            ></TextField>
                                        </div>
                                    </div>
                                    {ErrorCampo.gcaptcha.error?
                                        <div className="row justify-content-center">
                                            <Typography variant="caption" className="text-danger">* {ErrorCampo.gcaptcha.texto} *</Typography> 
                                        </div>:""
                                    }
                                    <div>{PrintConsole}</div>
                                    <div className="row mt-3 justify-content-center">
                                        <div className={ClaseInputs}>
                                            <Button
                                                type="submit"
                                                className="text-white"
                                                variant="contained"
                                                size="large"
                                                style={{
                                                    backgroundColor:
                                                        EstilosGlobales.colorPrimario,
                                                }}
                                            >
                                                Login
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                                <div className="d-flex flex-column mt-2">
                                    <Typography variant="body2">
                                        ¿ No tienes cuenta ?{" "}
                                        <Link
                                            className="ml-1 text-decoration-none font-weight-bold"
                                            style={{
                                                color:
                                                    EstilosGlobales.colorPrimario,
                                            }}
                                            to="/registrar"
                                        >
                                            Registrarme
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2">
                                        ¿ Has olvidado tu contraseña ?
                                        <Link
                                            className="ml-1 text-decoration-none font-weight-bold"
                                            style={{
                                                color:
                                                    EstilosGlobales.colorPrimario,
                                            }}
                                            to="/recuperar_pass"
                                        >
                                            Recuperar Contraseña
                                        </Link>
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const GCaptchaParentComponent=()=>{
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [gtoken,setGtoken] = useState('');

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const getTokenResult = await executeRecaptcha('yourAction');

        if(getTokenResult){
            setGtoken(getTokenResult);
        }
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return(
        <ContenidoLogin handleReCaptchaVerify={handleReCaptchaVerify} gtoken={gtoken} />
    )
}

export default GCaptchaParentComponent;