import {Typography} from '@material-ui/core'
import EstilosGlobales from './EstilosGlobales'

const ConfigModulos={
    CarroCompras:{
        agregar_carro:{
            mensajes:{
                sin_stock:{
                    html:<div><br/><b>Consulte</b> con nuestros vendedores.</div>
                }
            }
        }
    },
    Ventas:{
        carroet3:{
            mensaje:
            <div className="row justify-content-center">
                <div className="col-md-8 col-12 text-center my-3 text-white pt-3 pb-4 rounded shadow-sm border" style={{backgroundColor:EstilosGlobales.colorSecundario}}>
                    <Typography className="font-toremp" variant="h5">
                        ¡ Partida Finalizada !
                    </Typography>
                    <Typography className="font-toremp" variant="body1">
                        Felicitaciones! Nuestros recaudadores han recibido tus monedas de oro, por lo que nos autorizan la entrega de tu pedido.<br/>
    Ahora, debes esperar que los guardianes del Arbolito lleven cuidadosamente tu tesoro hasta los comerciantes y/o navegantes encargados de realizar el envío hasta la puerta de tu guarida.
                    </Typography>
                </div>
            </div>
        }
    },
    CatalogoProductos:{
        producto_detalle:{
            claseImagenes:"col-md-6 col-lg-6",
            claseDatosPrincipales:"col-md-6 col-lg-6"
        }
    }
}

export default ConfigModulos;
