const ConfigSecciones={
    "contacto":{
        "redes_sociales":{
            "activado":"true"
        }
    },
    "producto_ver":{
        "especificaciones_personalizadas":"true",
        "especificaciones_contenido":{
            "activada":"true"
        }
    }
}

export default ConfigSecciones;
