import {useState,useEffect} from 'react'
import EstilosGlobales from '../../toremp/EstilosGlobales';
import { CircularProgress,Button, Card, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import axios from 'axios'

export default function SideBarFiltros(props){
    let {DatosFiltros} = props.DatosFiltros;
    let {Filtros,setFiltros} = props.Filtros;
    let {ResetFiltros} = props.ResetFiltros;
    let {setEstado} = props.Estado;
    const [CargadoConfigFiltro,setCargadoConfigFiltro] = useState(false);
    const [ConfigFiltro,setConfigFiltro] = useState([]);

    useEffect(()=>{
        const cargarConfigFiltro=()=>{
            var datos = new FormData();
            datos.append("accion","cargar_config_filtros");
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/productos_cargar_filtros.php",
                method:"post",
                data:datos
            }).then((resp)=>{
                var res = resp.data;
                if(res[0]==="realizado"){
                    setCargadoConfigFiltro(true);
                    setConfigFiltro(res.config_filtro);
                }
            })      
        }
        cargarConfigFiltro();
    },[])

    if(!CargadoConfigFiltro){
        return(
            <div className={props.className}>
                <Card className="px-2 py-1">
                    <div className="row mx-1 p-2 justify-content-center py-3">
                        <div className="text-center">
                            <CircularProgress style={{color:EstilosGlobales.colorSecundario}}/>
                            <Typography>
                                Cargando...
                            </Typography>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }else{
        
        return(
            <div className={props.className}>
                <Card className="px-2 py-1">
                    <div className="row mx-1 p-2">
                        <Typography className="secondary">Filtrar por:</Typography>
                    </div>
                    <FiltroMarca DatosFiltros={DatosFiltros} Filtros={{Filtros,setFiltros}}/>
                    {
                        ConfigFiltro.map((filtroIndex,index)=>{
                            
                            if(parseInt(filtroIndex.filtrable)>0 && filtroIndex.tipo==="texto"){
                                return(
                                    <FiltroTexto filtroIndex={filtroIndex} DatosFiltros={DatosFiltros} Filtros={{Filtros,setFiltros}} key={index}/>
                                );
                            }else if(parseInt(filtroIndex.filtrable)>0 && filtroIndex.tipo==="numero"){
                                return(
                                    <FiltroNumero filtroIndex={filtroIndex} DatosFiltros={DatosFiltros} Filtros={{Filtros,setFiltros}} key={index}/>
                                );
                            }else{
                                return(<></>);
                            }
                        })                   
                    }
                    <div className="row mx-1">
                        <FormControlLabel
                            className="mx-1 my-0"
                            control={<Checkbox color="primary" checked={Filtros.destacado} onChange={(e)=>{setFiltros({...Filtros,destacado:e.target.checked})}}/>}
                            label="Destacados"
                            style={{marginBottom:"-10px"}}
                            />
                        <FormControlLabel
                            className="mx-1"
                            control={<Checkbox color="primary" checked={Filtros.oferta} onChange={(e)=>{setFiltros({...Filtros,oferta:e.target.checked})}}/>}
                            label="Oferta"
                            style={{marginBottom:"-10px"}}
                            />                    
                    </div>
                    <div className="row justify-content-end mx-1">
                        <Button onClick={() => {
                            setEstado("inicio");
                            if(Filtros.marca.length<=0 && !Filtros.destacado && !Filtros.oferta && 
                            Filtros.filtros_valor.esp1.length<=0 && 
                            Filtros.filtros_valor.esp2.length<=0 && 
                            Filtros.filtros_valor.esp3.length<=0 && 
                            Filtros.filtros_valor.esp4.length<=0 && 
                            Filtros.filtros_valor.esp5.length<=0 && 
                            Filtros.filtros_valor.esp6.length<=0 && 
                            Filtros.filtros_valor.esp7.length<=0 && 
                            Filtros.filtros_valor.esp8.length<=0 && 
                            Filtros.filtros_valor.esp9.length<=0 && 
                            Filtros.filtros_valor.esp10.length<=0 
                            )setFiltros({ ...Filtros, submited: false});
                            else setFiltros({...Filtros,submited:true});
                        }}
                            size="small" variant="contained" style={{ backgroundColor: EstilosGlobales.colorPrimario }} className="text-white mb-2 ml-2 col-md-1">Filtrar</Button>
                    </div>
                    {
                        Filtros.submited? 
                        <div className="row mx-1 justify-content-center align-items-center">
                            <div className="d-flex flex-row" style={{cursor:"pointer"}}
                                onClick={()=>{
                                    setFiltros(ResetFiltros);
                                    setEstado("inicio");
                                }} 
                            >
                                <button className="btn btn-dark font-weight-bold px-1 py-0" style={{backgroundColor:EstilosGlobales.colorSecundario}}>X</button>
                                <Typography variant="caption" className="ml-1 mt-1"><u>Quitar Filtros</u></Typography>
                            </div>
                        </div>:""
                    }
                </Card>
            </div>
        );

        /* ANTIG 
        return(
            <div className={props.className}>
                <Card className="px-2 py-1">
                    <div className="row mx-1 p-2">
                        <Typography className="secondary">Filtrar por:</Typography>
                    </div>
                    <div className="row mx-1 mt-2 justify-content-center align-items-center">
                        <select
                        onChange={(e)=>{
                            setFiltros({...Filtros,marca:e.target.value})
                        }}
                        className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.marca}>
                            <option value="">Marca</option>
                            {
                                DatosFiltros.marcas.map((marca,index)=>{
                                    if(marca.marca.length>0){
                                        return(
                                            <option key={index} value={marca.marca}>{marca.marca}</option>
                                        )
                                    }else{
                                        return false;
                                    }
                                })
                            }
                        </select>
                    </div>
                    <div className="row mx-1 mt-2 justify-content-center align-items-center">
                        <select
                        onChange={(e)=>{
                            setFiltros({...Filtros,carac_principal:e.target.value})
                        }}
                        className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.carac_principal}>
                            <option value="">Carac. Principal</option>
                            {
                                DatosFiltros.caracs_principales.map((carac_principal, index) => {
                                    if (carac_principal.esp1!==null && carac_principal.esp1.length > 0 ) {
                                        return (
                                            <option key={index} value={carac_principal.esp1}>{carac_principal.esp1}</option>
                                        )
                                    } else {
                                        return false;
                                    }
                                })
                            }
                        </select>
                    </div>
                    <div className="row mx-1 mt-2 justify-content-center align-items-center">
                        <select
                        onChange={(e)=>{
                            setFiltros({...Filtros,carac_secundaria:e.target.value})
                        }}
                        className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.carac_secundaria}>
                            <option value="">Carac. Secundaria</option>
                            {
                                DatosFiltros.caracs_secundarias.map((carac_secundaria, index) => {
                                    if (carac_secundaria.esp2!==null && carac_secundaria.esp2.length > 0 ) {
                                        return (
                                            <option key={index} value={carac_secundaria.esp2}>{carac_secundaria.esp2}</option>
                                        )
                                    } else {
                                        return false;
                                    }
                                })
                            }
                        </select>
                    </div>
                    <div className="row mx-0">
                    </div>
                    <div className="row mx-1">
                        <FormControlLabel
                            className="mx-1 my-0"
                            control={<Checkbox color="primary" checked={Filtros.destacado} onChange={(e)=>{setFiltros({...Filtros,destacado:e.target.checked})}}/>}
                            label="Destacados"
                            style={{marginBottom:"-10px"}}
                            />
                        <FormControlLabel
                            className="mx-1"
                            control={<Checkbox color="primary" checked={Filtros.oferta} onChange={(e)=>{setFiltros({...Filtros,oferta:e.target.checked})}}/>}
                            label="Oferta"
                            style={{marginBottom:"-10px"}}
                            />                    
                    </div>
                    <div className="row justify-content-end mx-1">
                        <Button onClick={() => {
                            setEstado("inicio");
                            if(Filtros.marca.length<=0 && !Filtros.destacado && !Filtros.oferta && Filtros.carac_principal.length<=0 && Filtros.carac_secundaria.length<=0)setFiltros({ ...Filtros, submited: false});
                            else setFiltros({...Filtros,submited:true});
                        }}
                            size="small" variant="contained" style={{ backgroundColor: EstilosGlobales.colorPrimario }} className="text-white mb-2 ml-2 col-md-1">Filtrar</Button>
                    </div>
                    {
                        Filtros.submited? 
                        <div className="row mx-1 justify-content-center align-items-center">
                            <div className="d-flex flex-row" style={{cursor:"pointer"}}
                                onClick={()=>{
                                    setFiltros(ResetFiltros);
                                    setEstado("inicio");
                                }} 
                            >
                                <button className="btn btn-dark font-weight-bold px-1 py-0" style={{backgroundColor:EstilosGlobales.colorSecundario}}>X</button>
                                <Typography variant="caption" className="ml-1 mt-1"><u>Quitar Filtros</u></Typography>
                            </div>
                        </div>:""
                    }
                </Card>

            </div>
        );*/
    }
}

function FiltroMarca(props){
    let {DatosFiltros} = props;
    let {Filtros,setFiltros} = props.Filtros;

    return(
        <div className="row mx-1 mt-2 justify-content-center align-items-center">
            <select
            onChange={(e)=>{
                setFiltros({...Filtros,marca:e.target.value})
            }}
            className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.marca}>
                <option value="">Marca</option>
                {
                    DatosFiltros.marcas.map((marca,index)=>{
                        if(marca.marca.length>0){
                            return(
                                <option key={index} value={marca.marca}>{marca.marca}</option>
                            )
                        }else{
                            return false;
                        }
                    })
                }
            </select>
        </div>
    );
}

function FiltroTexto(props){
    let {DatosFiltros} = props;
    let {Filtros,setFiltros} = props.Filtros;
    let {filtroIndex} = props;

    return(
        <div className="row mx-1 mt-2 justify-content-center align-items-center">
            {/*
                <button onClick={()=>{console.log(Filtros);console.log(DatosFiltros);}}>
                    Comprobar
                </button>*/}
            {filtroIndex['variable']?
                <select
                    onChange={(e)=>{
                        setFiltros({
                            ...Filtros,
                            filtros_valor:{
                                ...Filtros.filtros_valor,
                                [filtroIndex['variable']]:e.target.value
                            }
                        })
                    }}
                    className="form-control mx-1 mb-1 py-0 px-1"
                    value={Filtros.filtros_valor.[filtroIndex['variable']]} 
                >
                    <option value="">{filtroIndex.nombre}</option>
                    {
                        DatosFiltros.datos_filtros[filtroIndex['variable']]?
                        <>
                        {
                            DatosFiltros.datos_filtros[filtroIndex['variable']].map((dato,index)=>{
                                //console.log(dato);
                                return(
                                    <option key={index} value={dato[filtroIndex['variable']]}>{dato[filtroIndex['variable']]}</option>
                                )
                            })
                        }
                        </>:""
                    }
                </select>
                :""
            }
        </div>
    );
}

function FiltroNumero(props){
    let {DatosFiltros} = props;
    let {Filtros,setFiltros} = props.Filtros;
    let {filtroIndex} = props;

    return(
        <div className="row mx-1 mt-2 justify-content-center align-items-center">
            {/*
                <button onClick={()=>{console.log(Filtros);console.log(DatosFiltros);}}>
                    Comprobar
                </button>*/}
            {filtroIndex['variable']?
                <select
                    onChange={(e)=>{
                        setFiltros({
                            ...Filtros,
                            filtros_valor:{
                                ...Filtros.filtros_valor,
                                [filtroIndex['variable']]:e.target.value
                            }
                        })
                    }}
                    className="form-control mx-1 mb-1 py-0 px-1"
                    value={Filtros.filtros_valor.[filtroIndex['variable']]} 
                >
                    <option value="">{filtroIndex.nombre}</option>
                    {
                        DatosFiltros.datos_filtros[filtroIndex['variable']]?
                        <>
                        {
                            DatosFiltros.datos_filtros[filtroIndex['variable']].map((dato,index)=>{
                                //console.log(dato);
                                return(
                                    <option key={index} value={dato[filtroIndex['variable']]}>{dato[filtroIndex['variable']]}</option>
                                )
                            })
                        }
                        </>:""
                    }
                </select>
                :""
            }
        </div>
    );
}
