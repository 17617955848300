

import { Card, Typography,CardActionArea } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState,useContext } from 'react';
import { Redirect, useParams } from 'react-router';
import Ruta from '../Ruta'
import "./ContenidoSuperfamilias.css"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import Titulo from '../Titulo'
import { Skeleton } from '@material-ui/lab';
import LinearProgressMine from '../LinearProgressMine'
/* import YoutubeFrame from '../../context/YoutubeFrame' */
import ReactPlayer from 'react-player/youtube'
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'
//import {Button} from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
//META
import {Helmet} from 'react-helmet-async'
import DatosConfigContext from '../../context/config.jsx'





window.YTConfig = {
    host: 'https://www.youtube.com' 
} 


export default function ContenidoSuperfamilias() {
    let { superfamilia } = useParams();

    const [Estado, setEstado] = useState("inicio");
    const [Rutas, setRutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Cargando..." }
    ]);

    const [SuperfamiliaActual,setSuperfamiliaActual] = useState(superfamilia);

    const [DatosSuperfamilia, setDatosSuperfamilia] = useState(
        {
            "0": "realizado",
            "familias": [
                {
                }
            ],
            "superfamilia": {
                "ID": "",
                "nombre": "",
                "img": ""
            }
        }
    );




    useEffect(() => {
        if (Estado === "inicio") {
            cargarSuperfamilia();
        }
        if(SuperfamiliaActual!==superfamilia){
            cargarSuperfamilia();
        }

    })

    const cargarSuperfamilia = () => {

        setEstado("cargando");
        setSuperfamiliaActual(superfamilia);

        var datos = new FormData();
        datos.append("superfamilia", superfamilia);

        axios({
            url: process.env.PUBLIC_URL + "/assets/api/superfamilia_cargar.php",
            method: "post",
            data: datos
        }).then((resp) => {
            var res = resp.data;
             /* console.log(res); */
            if (res[0] === "realizado") {
                setEstado("cargado");
                setRutas([
                    { nombre: "Inicio", ruta: "/" },
                    { nombre: res['superfamilia']['nombre'] }
                ]);
                setDatosSuperfamilia(res);

            }else if(res[0]==="error"){
                setEstado("error");
            }
        })
    }

    const {DatosConfig} = useContext(DatosConfigContext);


    if (Estado === "inicio" || Estado === "cargando") {
        return (
            <div>
                <Ruta ruta={Rutas}></Ruta>
                <div className="container-fluid p-0 bg-light overflow-hidden position-relative" style={{ height: "200px", overflow: "hidden" }}>
                    <Skeleton variant="rect" className="h-100"></Skeleton>
                </div>
                <div className="bg-light" style={{minHeight:window.innerHeight*0.6}}>
                    <div className="container-fluid px-0">
                        <LinearProgressMine></LinearProgressMine>
                    </div>
                    <div className="container">
                        
                        <div className="row py-5">
                            <div className="col-md-4">
                                <Skeleton variant="rect" height={200}></Skeleton>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                    <div className="col-6 col-md-4 my-2">
                                        <Skeleton variant="rect" height={200}></Skeleton> 
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    } else if (Estado === "error") {
        return (
            <div>
                <Redirect to="/404"></Redirect>
            </div>
        );
    } else if (Estado === "cargado" && DatosSuperfamilia) {


        return (
            <div>
                <Helmet>
                    <title>{`${DatosSuperfamilia.superfamilia.nombre}`} - {`${DatosConfig.Empresa.nombre}`} </title>
                </Helmet>
                <Ruta ruta={Rutas}></Ruta>
                <div className="container-fluid p-0 bg-dark overflow-hidden position-relative" style={{ height: "200px", overflow: "hidden" }}>
                    <div className="position-absolute w-100">
                        <img src={process.env.PUBLIC_URL+"/assets/imagenes/superfamilias/"+DatosSuperfamilia.superfamilia.img} className="w-100 position-absolute imagen_banner" alt="ban" style={{filter:"blur(10px)",transform:"translate(0,-50%)",top:"50%"}}></img>
                        <div className="position-absolute w-100" style={{ zIndex: "2", height: "200px", opacity: "0.3", backgroundColor: "#000000" }}></div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className="w-100 py-2" style={{ backgroundColor: EstilosGlobales.colorPrimario }}>
                    </div>
                    <div className="container">
                        <div className="row position-relative justify-content-center">
                            <div className="position-absolute d-flex flex-row align-items-center cont_general_titulo">
                                <div className="bg-white shadow-sm p-2 text-center" style={{ zIndex: "4",borderRadius:"12px 12px 12px 12px" }}>
                                    <img className="imagen_superfamilia" src={process.env.PUBLIC_URL + "/assets/imagenes/superfamilias/" + DatosSuperfamilia.superfamilia.img} alt=""></img>
                                </div>
                                <div className="px-3 py-1 rounded position-relative" style={{ left: "0px", zIndex: "5" }}>
                                    <Typography className="titulo_superfamilia font-weight-bold text-white" variant="h4">{DatosSuperfamilia.superfamilia.nombre}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container py-2">
                        <div className="row pt-5 pb-2">
                            {
                                DatosSuperfamilia?
                                <div className="col-lg-4 mb-4">
                                    <div className="accordion" id="accordionExample">
                                            {
                                                DatosSuperfamilia.familias.map((familia,index)=>{

                                                    
                                                    if(familia.familia && familia.familia.length>0 && familia.subfamilias && familia.subfamilias.length>0 && familia.subfamilias[0].subfamilia!==''){
                                                        return(                                                                        
                                                            
                                                            <Card className="card" key={index}>
                                                                <div className="card-header p-0" id="headingOne">
                                                                    <h2 className="mb-0">
                                                                        <button className="btn btn-link btn-block text-left text-decoration-none bg-white d-flex flex-row justify-content-between" type="button" data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index}>
                                                                            <Typography className="text-dark">
                                                                                {familia.familia}
                                                                            </Typography>
                                                                            {
                                                                            familia.subfamilias && familia.subfamilias.length>0 && familia.subfamilias[0].subfamilia!==''?
                                                                            <ExpandMoreIcon style={{color:EstilosGlobales.colorPrimario}}></ExpandMoreIcon>:
                                                                            <div className="btn text-decoration-none px-1 py-0 mx-0" size="small" style={{color:EstilosGlobales.colorSecundario}}>
                                                                                <PlayCircleFilledIcon/>       
                                                                            </div>
                                                                            }
                                                                        </button>
                                                                    </h2>
                                                                </div>

                                                                <div id={"collapse"+index} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                    <div className="card-body py-1">
                                                                        {familia.subfamilias && familia.subfamilias.length>0 && familia.subfamilias[0].subfamilia!==''?
                                                                        <div className="d-flex flex-column">
                                                                            {
                                                                                familia.subfamilias.map((subfamilia,index)=>{
                                                                                    return(
                                                                                        <Link className="text-decoration-none" to={"/productos/"+encodeURIComponent(superfamilia)+"/"+encodeURIComponent(familia.familia)+"/"+encodeURIComponent(subfamilia.subfamilia)+"/subfamilia"} key={index}>
                                                                                            <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorSecundario}} variant="caption">
                                                                                                {subfamilia.subfamilia}
                                                                                            </Typography>
                                                                                        </Link>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>:""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            
                                                            </Card>      
                                                        );
                                                    }else if(familia.familia && familia.familia.length>0 ){
                                                        return(                                                                        
                                                            
                                                            <Card className="card" key={index}>
                                                                <Link className="text-decoration-none" to={"/productos/"+encodeURIComponent(superfamilia)+"/"+encodeURIComponent(familia.familia)+"/emp/familia"}>
                                                                <div className="card-header p-0" id="headingOne">
                                                                    <h2 className="mb-0">
                                                                        <button className="btn btn-link btn-block text-left text-decoration-none bg-white d-flex flex-row justify-content-between" type="button" data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index}>
                                                                            <Typography className="text-dark">
                                                                                {familia.familia}
                                                                            </Typography>
                                                                            <div className="btn text-decoration-none px-1 py-0 mx-0" size="small" style={{color:EstilosGlobales.colorSecundario}}>
                                                                                <PlayCircleFilledIcon/>       
                                                                            </div>
                                                                            
                                                                        </button>
                                                                    </h2>
                                                                </div>
                                                                </Link>
                                                            </Card>      
                                                        );
                                                    }else{
                                                        return(
                                                            <div className="" key={index}>
                                                            </div>
                                                        );
                                                    }
                                                })
                                            }                             
                                    </div>
                                </div>                                
                                :
                                ""
                            }   

                            <div className="col-lg-8">                                                         

                                <Titulo className="px-3 px-sm-0" titulo="Categorias"></Titulo>
                                {DatosSuperfamilia.familias && DatosSuperfamilia.familias.length>0?
                                    <div className="row py-4">
                                        {
                                            DatosSuperfamilia.familias.map((familia,index)=>{                                                                     if(familia['subfamilias']){ 
                                                    if(familia.familia.length<=0){
                                                        return(
                                                            <div key={index}>
                                                            </div>
                                                        );
                                                    }
                                                    var subfamilia_fn = "emp";
                                                    if(familia['subfamilias'][0]['subfamilia'] && familia['subfamilias'][0]['subfamilia'].length>0){
                                                        subfamilia_fn=familia['subfamilias'][0]['subfamilia'];
                                                    }
                                                    return(
                                                        <div key={index} className="col-6 col-md-4 my-2">
                                                            <Link className="text-decoration-none" to={"/productos/"+encodeURIComponent(DatosSuperfamilia.superfamilia.nombre_data)+"/"+encodeURIComponent(familia.familia)+"/"+encodeURIComponent(subfamilia_fn)+"/familia"}>
                                                                <Card>
                                                                    <CardActionArea>
                                                                        <div className="overflow-hidden contenedor_imagen_familia">
                                                                            <img src={process.env.PUBLIC_URL + "/assets/imagenes/productos/"+familia.img_01} alt="Sup" className="img-fluid"/>
                                                                        </div>
                                                                    </CardActionArea>
                                                                    <Typography style={{lineHeight:"1em",minHeight:"3rem"}} className="text-center mt-2 font-weight-bold px-2 nombre_familia_img">{familia.familia}</Typography>

                                                                </Card>
                                                            </Link>
                                                        </div>
                                                    );
                                                }else{                                                    
                                                    return(
                                                        <div key={index}></div>
                                                    );
                                                }
                                            })
                                        }
                                    </div>
                                    :""
                                }
                            </div>                     
                        </div>
                        {DatosSuperfamilia.superfamilia.capsulas && DatosSuperfamilia.superfamilia.capsulas.length>0?
                            <div>
                                <Titulo className="px-3 px-sm-0" titulo="Cápsulas"></Titulo>                            
                                <div className="row py-3">                            
                                    {
                                        DatosSuperfamilia.superfamilia.capsulas.map((capsula,index)=>{           
                                            return(                         
                                                <ReactPlayer key={index} url={capsula.link} className="col-md-6">                                
                                                </ReactPlayer>                                                  
                                            );                                    
                                        })
                                    }              
                                </div>
                            </div>
                            :""
                        }
                    </div>
                </div>
            </div>
        );


    }
}
