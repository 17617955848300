import SeccionMenu from './contenido/index.json';

var submenu_empresa=[];

SeccionMenu.secciones.map((seccion_submenu)=>{
    submenu_empresa.push({titulo:seccion_submenu.titulo,linkto:"/acercaden/"+seccion_submenu.ruta});
    return <></>;
});

const ConfigMenu = {
    "configuracion":{
        "tipo_logo":"2",
        "menu_datos":{
            "activado":"true",
            "menu":"datos",
            "variable":"",
            "contenido":[
                {
                    "titulo":"Telefono",
                    "variable": "telefono"
                },
                {
                    "titulo":"Email",
                    "variable": "email"
                }
            ]
        },
        "menu_blog":{
            "activado":"false"
        },
        "menu_iconos":{
            "activado":"true",
            "menu":"iconos",
            "variable":"",
            "boton_sucursales":{
                "activado":"true"
            },
            "boton_webpay":{
                "activado":"true",
                "texto":""
            },
            "contenido":[
                {
                    "titulo":"Whatsapp",
                    "variable": "whatsapp"
                },
                {
                    "titulo":"Facebook",
                    "variable": "facebook"
                },
                {
                    "titulo":"Instagram",
                    "variable": "instagram"
                }
            ]
        }
    },
    "menu_categorias_root":{
        "activado":"false"
    },
    "menu_sucursales":{
        "activado":"false"
    },
    "menu_down":{
        "estructura":[
            {titulo:"Inicio",linkto:"/",tipo:"default"},
            {titulo:"La Guarida",linkto:"/acercaden",tipo:"dropdown",
               /*submenu:[
               ]*/
               submenu:submenu_empresa
            },
            {titulo:"Contacto",linkto:"/contacto",tipo:"default"},
            {titulo:"Seguimiento",linkto:"/tracking",tipo:"default"}
        ],
        "menu_categorias":{
            "activado":"true",
            "titulo":"Nuestros Juegos"
        },
        "menu_blog":{
            "activado":"false",
            "titulo":"Seccio"
        }
    }
}

export default ConfigMenu;
