import { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Skeleton } from "@material-ui/lab";
import EstilosGlobales from "../../toremp/EstilosGlobales";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ConfigGeneral from "../../toremp/ConfigGeneral.jsx";
import useConfigEtiqueta from '../../context/ConfigEtiqueta.jsx'

export default function SideBar(props) {
    const {DatosCarga,setDatosCarga} = props;

    const ConfigEtiqueta = useConfigEtiqueta();

    if (ConfigEtiqueta==="etiquetas"){
        return(

            <SideBarEtiquetas/>
        )
    }else if (ConfigGeneral.inicio.categoria_productos.capa === "superfamilia") {
        return <SideBarSuperfamilia {...props} />;
    } else if (ConfigGeneral.inicio.categoria_productos.capa === "familia") {
        return <SideBarFamilia {...props} />;
    }
}

function SideBarEtiquetas(props) {
    const [Estado, setEstado] = useState("inicio");
    const [Etiquetas,setEtiquetas] = useState([]);

    useEffect(()=>{
        const cargarEtiquetas = () => {
            setEstado("cargando");
            axios({
                url:
                    process.env.PUBLIC_URL + "/assets/api/etiquetas_productos_cargar.php",
            }).then((resp) => {
                var res = resp.data;
                setEstado("cargado");
                setEtiquetas(res);
            });
        };

        cargarEtiquetas();
    },[])


    /*const handleClickSubfamilia = () => {
        props.EstadoPadre.setEstado("inicio");
    };*/

    if (Estado === "inicio" || Estado === "cargando") {
        return (
            <div className={props.className}>
                <Skeleton variant="rect" height={630}></Skeleton>
            </div>
        );
    } else if (Estado === "cargado") {
        return(
            <div className={props.className}>
                <div className="rounded border shadow-sm bg-white">
                    {
                        Etiquetas.map((etiqueta,index)=>{
                            return(
                                <Link key={index} className="text-decoration-none d-flex flex-row w-100 align-items-center justify-content-start" to={"/productos/"+encodeURIComponent(etiqueta.etiqueta)+"/imp/imp/superfamilia/"+index} 
                                    onClick={()=>{
                                        window.scroll(0,0);
                                    }} 
                                >
                                    <ArrowRightIcon style={{color:EstilosGlobales.colorSecundario}}/>
                                    <Button className="">
                                        <Typography className="text-left" variant="body2">
                                            {etiqueta.etiqueta} 
                                        </Typography>
                                    </Button>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

function SideBarSuperfamilia(props) {
    const [Estado, setEstado] = useState("inicio");
    const [Categorias, setCategorias] = useState([{}]);

    useEffect(() => {
        if (Estado === "inicio") {
            cargarCategorias();
        }
    });

    const cargarCategorias = () => {
        setEstado("cargando");
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/superfamilia_familia_subfamilia_cargar.php",
        }).then((resp) => {
            var res = resp.data;
            setCategorias(res);
            setEstado("cargado");
        });
    };

    const handleClickSubfamilia = () => {
        props.EstadoPadre.setEstado("inicio");
    };

    if (Estado === "inicio" || Estado === "cargando") {
        return (
            <div className={props.className}>
                <Skeleton variant="rect" height={630}></Skeleton>
            </div>
        );
    } else if (Estado === "cargado") {
        return (
            <div className={props.className}>
                <div id="accordion">
                    {Categorias.map((superfamilia, index) => {
                        return (
                            <div className="card" key={index}>
                                <div
                                    className="card-header p-0"
                                    id="headingOne"
                                    style={{
                                        backgroundColor:
                                            EstilosGlobales.especifico
                                                .sidebarProductos.superfamilias
                                                .fondo,
                                    }}
                                >
                                    <h5 className="mb-0">
                                        <Button
                                            className="w-100 d-flex justify-content-between"
                                            data-toggle="collapse"
                                            data-target={"#collapse" + index}
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            <Typography
                                                className="font-weight-bold text-left"
                                                style={{
                                                    color:
                                                        EstilosGlobales
                                                            .especifico
                                                            .sidebarProductos
                                                            .superfamilias
                                                            .texto,
                                                }}
                                                variant="caption"
                                            >
                                                {superfamilia.superfamilia}
                                            </Typography>
                                            <ExpandMoreIcon
                                                style={{
                                                    color:
                                                        EstilosGlobales
                                                            .especifico
                                                            .sidebarProductos
                                                            .superfamilias
                                                            .arrow,
                                                }}
                                                fontSize="small"
                                            ></ExpandMoreIcon>
                                        </Button>
                                    </h5>
                                </div>

                                {superfamilia.familias &&
                                superfamilia.familias.length > 0 ? (
                                    <div
                                        id={"collapse" + index}
                                        className="collapse"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body py-0 px-4">
                                            <div id="subaccordion">
                                                {superfamilia.familias.map(
                                                    (familia, indexj) => {
                                                        if (
                                                            familia.familia &&
                                                            familia.familia
                                                                .length > 0 &&
                                                            familia.familia !==
                                                                ""
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={indexj}
                                                                >
                                                                    <div id="headingSub">
                                                                        <Button
                                                                            className="py-1 px-0 w-100 d-flex justify-content-between"
                                                                            data-toggle="collapse"
                                                                            data-target={
                                                                                "#collapseSub" +
                                                                                index +
                                                                                indexj
                                                                            }
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseOne"
                                                                        >
                                                                            <Typography
                                                                                className="font-weight-bold text-left"
                                                                                variant="caption"
                                                                            >
                                                                                {
                                                                                    familia.familia
                                                                                }
                                                                            </Typography>
                                                                            <ExpandMoreIcon
                                                                                style={{
                                                                                    color:
                                                                                        EstilosGlobales
                                                                                            .especifico
                                                                                            .sidebarProductos
                                                                                            .familias
                                                                                            .arrow,
                                                                                }}
                                                                                fontSize="small"
                                                                            ></ExpandMoreIcon>
                                                                        </Button>
                                                                    </div>
                                                                    <div
                                                                        id={
                                                                            "collapseSub" +
                                                                            index +
                                                                            indexj
                                                                        }
                                                                        className="collapse"
                                                                        aria-labelledby="headingSub"
                                                                        data-parent="#subaccordion"
                                                                    >
                                                                        <div className="d-flex flex-column">
                                                                            {familia.subfamilias.map(
                                                                                (
                                                                                    subfamilia,
                                                                                    indexz
                                                                                ) => {
                                                                                    if (
                                                                                        subfamilia.subfamilia &&
                                                                                        subfamilia
                                                                                            .subfamilia
                                                                                            .length >
                                                                                            0 &&
                                                                                        subfamilia.subfamilia !==
                                                                                            ""
                                                                                    ) {
                                                                                        return (
                                                                                            <Link
                                                                                                className="text-decoration-none"
                                                                                                to={
                                                                                                    "/productos/" +
                                                                                                    encodeURIComponent(
                                                                                                        superfamilia.superfamilia
                                                                                                    ) +
                                                                                                    "/" +
                                                                                                    encodeURIComponent(
                                                                                                        familia.familia
                                                                                                    ) +
                                                                                                    "/" +
                                                                                                    encodeURIComponent(
                                                                                                        subfamilia.subfamilia
                                                                                                    ) +
                                                                                                    "/subfamilia"
                                                                                                }
                                                                                                key={
                                                                                                    indexz
                                                                                                }
                                                                                            >
                                                                                                <ArrowRightIcon
                                                                                                    style={{
                                                                                                        color:
                                                                                                            EstilosGlobales
                                                                                                                .especifico
                                                                                                                .sidebarProductos
                                                                                                                .subfamilias
                                                                                                                .arrow,
                                                                                                    }}
                                                                                                ></ArrowRightIcon>
                                                                                                <Typography
                                                                                                    onClick={
                                                                                                        handleClickSubfamilia
                                                                                                    }
                                                                                                    className="text-dark"
                                                                                                    variant="caption"
                                                                                                >
                                                                                                    {
                                                                                                        subfamilia.subfamilia
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Link>
                                                                                        );
                                                                                    }else{
                                                                                        return(<></>);
                                                                                    }
                                                                                }
                                                                            )}
                                                                            <Link
                                                                                className="text-decoration-none"
                                                                                to={
                                                                                    "/productos/" +
                                                                                    encodeURIComponent(
                                                                                        superfamilia.superfamilia
                                                                                    ) +
                                                                                    "/" +
                                                                                    encodeURIComponent(
                                                                                        familia.familia
                                                                                    ) +
                                                                                    "/" +
                                                                                    encodeURIComponent(
                                                                                        "emp"
                                                                                    ) +
                                                                                    "/familia"
                                                                                }
                                                                                onClick={handleClickSubfamilia}
                                                                            >
                                                                                <ArrowRightIcon
                                                                                    style={{
                                                                                        color:
                                                                                            EstilosGlobales
                                                                                                .especifico
                                                                                                .sidebarProductos
                                                                                                .subfamilias
                                                                                                .arrow,
                                                                                    }}
                                                                                />
                                                                                <Typography
                                                                                    className="font-weight-bold"
                                                                                    variant="caption"
                                                                                    style={{
                                                                                        color:
                                                                                            EstilosGlobales
                                                                                                .especifico
                                                                                                .sidebarProductos
                                                                                                .subfamilias
                                                                                                .arrow,
                                                                                    }}
                                                                                >
                                                                                    Ver
                                                                                    Todo
                                                                                </Typography>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div
                                                                    className=""
                                                                    key={indexj}
                                                                ></div>
                                                            );
                                                        }
                                                    }
                                                )}
                                                <Link
                                                    to={
                                                        "/productos/" +
                                                        encodeURIComponent(
                                                            superfamilia.superfamilia
                                                        ) +
                                                        "/" +
                                                        encodeURIComponent(
                                                            "emp"
                                                        ) +
                                                        "/" +
                                                        encodeURIComponent(
                                                            "emp"
                                                        ) +
                                                        "/superfamilia"
                                                    }
                                                    className="text-decoration-none"
                                                    onClick={handleClickSubfamilia}
                                                >
                                                    <Button className="py-1 px-0 w-100 d-flex justify-content-between">
                                                        <Typography
                                                            className="font-weight-bold text-left"
                                                            variant="caption"
                                                            style={{
                                                                color:
                                                                    EstilosGlobales.colorPrimario,
                                                            }}
                                                        >
                                                            Ver Todo
                                                        </Typography>
                                                        <PlayCircleFilledIcon
                                                            style={{
                                                                color:
                                                                    EstilosGlobales.colorPrimario,
                                                            }}
                                                            fontSize="small"
                                                        />
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function SideBarFamilia(props) {
    const [Categorias, setCategorias] = useState([{}]);
    const [Cargado,setCargado] = useState(false);

    useEffect(() => {
        const cargarCategorias = () => {

            var datos = new FormData();

            datos.append("accion", "cargar_categorizacion");

            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/familia_subfamilia_cargar.php",
                method: "post",
                data: datos,
            }).then((resp) => {
                var res = resp.data;
                //console.log(res);
                setCategorias(res);
                setCargado(true);
            });
        };

        cargarCategorias();
    }, []);

    const handleClickSubfamilia = () => {
        props.EstadoPadre.setEstado("inicio");
    };

    if (!Cargado) {
        return (
            <div className={props.className}>
                <Skeleton variant="rect" height={630}></Skeleton>
            </div>
        );
    } else {
        return (
            <div className={props.className}>
                <div id="accordion">
                    <div className="card-body bg-white py-2 border shadow-sm rounded px-4">
                        <div id="subaccordion">
                            {Categorias.map((familia, indexj) => {
                                if (true
                                ) {
                                    return (
                                        <div key={indexj}>
                                            <div id="headingSub">
                                                <Button
                                                    className="py-1 px-0 w-100 d-flex justify-content-between"
                                                    data-toggle="collapse"
                                                    data-target={
                                                        "#collapseSub" +
                                                        indexj
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                >
                                                    <Typography
                                                        className="font-weight-bold text-left"
                                                        variant="caption"
                                                    >
                                                        {familia.nombre}
                                                    </Typography>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            color:
                                                                EstilosGlobales
                                                                    .especifico
                                                                    .sidebarProductos
                                                                    .familias
                                                                    .arrow,
                                                        }}
                                                        fontSize="small"
                                                    ></ExpandMoreIcon>
                                                </Button>
                                            </div>
                                            <div
                                                id={
                                                    "collapseSub" +
                                                    indexj
                                                }
                                                className="collapse"
                                                aria-labelledby="headingSub"
                                                data-parent="#subaccordion"
                                            >
                                                <div className="d-flex flex-column">
                                                    {familia.subfamilias.map(
                                                        (
                                                            subfamilia,
                                                            indexz
                                                        ) => {
                                                            if (
                                                                subfamilia.subfamilia &&
                                                                subfamilia
                                                                    .subfamilia
                                                                    .length >
                                                                    0 &&
                                                                subfamilia.subfamilia !==
                                                                    ""
                                                            ) {
                                                                return (
                                                                    <Link
                                                                        className="text-decoration-none"
                                                                        to={
                                                                            "/productos/" +
                                                                            encodeURIComponent(
                                                                                familia.superfamilia
                                                                            ) +
                                                                            "/" +
                                                                            encodeURIComponent(
                                                                                familia.nombre
                                                                            ) +
                                                                            "/" +
                                                                            encodeURIComponent(
                                                                                subfamilia.subfamilia
                                                                            ) +
                                                                            "/subfamilia"
                                                                        }
                                                                        key={
                                                                            indexz
                                                                        }
                                                                    >
                                                                        <ArrowRightIcon
                                                                            style={{
                                                                                color:
                                                                                    EstilosGlobales
                                                                                        .especifico
                                                                                        .sidebarProductos
                                                                                        .subfamilias
                                                                                        .arrow,
                                                                            }}
                                                                        ></ArrowRightIcon>
                                                                        <Typography
                                                                            onClick={
                                                                                handleClickSubfamilia
                                                                            }
                                                                            className="text-dark"
                                                                            variant="caption"
                                                                        >
                                                                            {
                                                                                subfamilia.subfamilia
                                                                            }
                                                                        </Typography>
                                                                    </Link>
                                                                );
                                                            }else{
                                                                return(<></>);
                                                            }
                                                        }
                                                    )}
                                                    <Link
                                                        className="text-decoration-none"
                                                        to={
                                                            "/productos/" +
                                                            encodeURIComponent(
                                                                familia.superfamilia
                                                            ) +
                                                            "/" +
                                                            encodeURIComponent(
                                                                familia.nombre
                                                            ) +
                                                            "/" +
                                                            encodeURIComponent(
                                                                "emp"
                                                            ) +
                                                            "/familia"
                                                        }
                                                        onClick={handleClickSubfamilia}
                                                    >
                                                        <ArrowRightIcon
                                                            style={{
                                                                color:
                                                                    EstilosGlobales
                                                                        .especifico
                                                                        .sidebarProductos
                                                                        .subfamilias
                                                                        .arrow,
                                                            }}
                                                        />
                                                        <Typography
                                                            className="font-weight-bold"
                                                            variant="caption"
                                                            style={{
                                                                color:
                                                                    EstilosGlobales
                                                                        .especifico
                                                                        .sidebarProductos
                                                                        .subfamilias
                                                                        .arrow,
                                                            }}
                                                        >
                                                            Ver Todo
                                                        </Typography>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="" key={indexj}></div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
