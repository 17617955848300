import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";
import LinearProgressMine from "../LinearProgressMine";
import { Link } from "react-router-dom";
import { validate, clean, format } from "rut.js";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Swal from '../../context/SwalAlert.jsx'
import EstilosGlobales from '../../toremp/EstilosGlobales.jsx'
//Select Sucursales
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl2 from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormChilexpress from "./FormChilexpress";

export default function FormularioCarroEt2(props) {
    let history = useHistory();

    let { ErrorCampos, setErrorCampos } = props.ErrorCamposPadre;
    let { tipo_despacho } = props.DatosVentaPadre.DatosVenta;
    let { setDatosVenta, DatosVenta } = props.DatosVentaPadre;
    let { DatosNoSubmit, setDatosNoSubmit } = props.DatosNoSubmitPadre;
    let { Region, setRegion } = props.Region;
    let { LocalidadDisabled, setLocalidadDisabled } = props.LocalidadDisabled;
    let { Localidades, setLocalidades } = props.Localidades;
    let {DatosFormChilexpress,setDatosFormChilexpress,Sidenc} = props.Propiedades

    const [Estado, setEstado] = useState("inicio");

    const [EstadoRegiones, setEstadoRegiones] = useState("inicio");
    const [Regiones, setRegiones] = useState([]);

    const [FormDespacho, setFormDespacho] = useState({
        retiro: false,
        despacho: false,
    });

    const [Informacion,setInformacion] = useState({
        severity:"info",
        display:false,
        texto:""
    });

    const [SucursalesRetiro,setSucursalesRetiro] = useState([]);
    const [DirSucursalRetiroPrint,setDirSucursalRetiroPrint] = useState("");

    useEffect(() => {
        if (Estado === "inicio") {
            cargarSucursalesRetiro();
        }
        if (EstadoRegiones === "inicio") {
            cargarRegiones();
        }
    });



    const cargarSucursalesRetiro= () => {
        setEstado("cargando");
        /* console.log(props.DatosVentaPadre.DatosVenta.tipo_despacho); */
        var datos = new FormData();
        datos.append("accion","cargar_sucursales_retiro");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/sucursales/ventas_cargar_sucursales_retiro.php",
            method:"post",
            data:datos
        }).then((resp) => {
            var res = resp.data;
            setSucursalesRetiro(res);
            setEstado("cargado");
        });
    };

    const cargarRegiones = () => {
        setEstadoRegiones("cargando");
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despdn/regiones_cargar.php",
        }).then((resp) => {
            var res = resp.data;
            /* console.log(res); */
            setRegiones(res);
            setEstadoRegiones("cargado");
        });
    };

    useEffect(() => {
        const comprobarProductosDespRet = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/carrodn/carrodn_comprobar_prods_despret.php",
            }).then((resp) => {
                var res = resp.data;
                //console.log(res);
                if (res[0] === "realizado") {
                    if (
                        res["retirables"] > 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 1
                        setFormDespacho({
                            retiro: false,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Despacho",
                        });
                        setInformacion({
                            severity:"info",
                            display:true,
                            texto:<div>El <b>retiro en tienda</b> se encuentra <b>no disponible</b>, ya que hay productos que se encuentran no disponibles para ser retirados. (Si desea cambiar esto, modifique su compra.)</div>
                        })
                    } else if (
                        res["retirables"] > 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] <= 0
                    ) {//CASO 2
                        setFormDespacho({
                            retiro: false,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Despacho",
                        });
                        setInformacion({
                            severity:"info",
                            display:true,
                            texto:<div>El <b>retiro en tienda</b> se encuentra <b>no disponible</b>, ya que hay productos que se encuentran no disponibles para ser retirados. (Si desea cambiar esto, modifique su compra.)</div>
                        })
                    }else if (
                        res["retirables"] <= 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 3
                        setFormDespacho({
                            retiro: false,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Despacho",
                        });
                        setInformacion({
                            severity:"error",
                            display:true,
                            texto:<div>Uno de los productos que intentas comprar se encuentra no disponible. Sentimos las molestias. El producto ha sido retirado de tu carro de compras para poder continuar. <b>NOTA 2:</b> Retiro en tienda desactivado por disponibilidad de retiro de productos.</div>
                        })
                        if(res['cantidad_productos']<=0){
                            Swal.fire({
                                title:"Producto(s) No Disponible(s)",
                                html:"El(los) producto(s) que intentas comprar se encuentra(n) no disponible. Sentimos las molestias.",
                                icon:"warning"
                            }).then(()=>{
                                history.push("/carro");
                            })
                        }
                    }else if (
                        res["retirables"] <= 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] <= 0
                    ) {//CASO 4
                        setFormDespacho({
                            retiro: false,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Despacho",
                        });
                        setInformacion({
                            severity:"info",
                            display:true,
                            texto:<div>Retiro en tienda desactivado por disponibilidad de retiro de productos.</div>
                        })
                    }else if (
                        res["retirables"] <= 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] <= 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 5
                        setFormDespacho({
                            retiro: false,
                            despacho: false,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "",
                        });
                        setInformacion({
                            severity:"error",
                            display:true,
                            texto:<div>El(los) producto(s) que intentas comprar se encuentra no disponible. Sentimos las molestias. El(los) producto(s) ha(n) sido retirado de tu carro de compras. </div>
                        })
                        Swal.fire({
                            title:"Producto(s) No Disponible(s)",
                            html:"El(los) producto(s) que intentas comprar se encuentra(n) no disponible. Sentimos las molestias.",
                            icon:"warning"
                        }).then(()=>{
                            history.push("/carro");
                        })
                    }else if (
                        res["retirables"] > 0 &&
                        res["no_retirables"] > 0 &&
                        res["despachables"] <= 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 6
                        setFormDespacho({
                            retiro: true,
                            despacho: false,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Retiro",
                        });
                        setInformacion({
                            severity:"error",
                            display:true,
                            texto:<div>Alguno de los productos que intentas comprar se encuentra no disponible. Sentimos las molestias. El(los) producto(s) ha(n) sido retirado de tu carro de compras. <b>NOTA 2: </b>Despacho a domicilio desactivado por disponibilidad de despacho de los productos.</div>
                        })
                        if(res['cantidad_productos']<=0){
                            Swal.fire({
                                title:"Producto(s) No Disponible(s)",
                                html:"El(los) producto(s) que intentas comprar se encuentra(n) no disponible. Sentimos las molestias.",
                                icon:"warning"
                            }).then(()=>{
                                history.push("/carro");
                            })

                        }
                    }else if (
                        res["retirables"] > 0 &&
                        res["no_retirables"]<= 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 7
                        setFormDespacho({
                            retiro: true,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Retiro",
                        });
                        setInformacion({
                            severity:"info",
                            display:true,
                            texto:<div>Alguno de los productos que intentas comprar, deberás <b>retirarlos en tienda</b>.</div>
                        })
                    }else if (
                        res["retirables"] > 0 &&
                        res["no_retirables"]<= 0 &&
                        res["despachables"] > 0 &&
                        res["no_despachables"] <= 0
                    ) {//CASO 8
                        setFormDespacho({
                            retiro: true,
                            despacho: true,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Retiro",
                        });
                        setInformacion({
                            severity:"info",
                            display:false,
                            texto:""
                        })
                    }else if (
                        res["retirables"] > 0 &&
                        res["no_retirables"]<= 0 &&
                        res["despachables"] <= 0 &&
                        res["no_despachables"] > 0
                    ) {//CASO 9 
                        setFormDespacho({
                            retiro: true,
                            despacho: false,
                        });
                        setDatosVenta({
                            ...DatosVenta,
                            tipo_despacho: "Retiro",
                        });
                        setInformacion({
                            severity:"info",
                            display:false,
                            texto:<div>Despacho a domicilio desactivado por disponibilidad de despacho de los productos.</div>
                        })
                    }
                } else {
                    history.push("/carro");
                }
            });
        };

        comprobarProductosDespRet();
    }, []);

    const ClaseContTextField = "col-md-6 mt-2";
    return (
        <div className={props.className}>
            <Typography className="font-weight-bold" variant="h6">
                Despacho del pedido
            </Typography>
            <div className="bg-white py-2 my-1 shadow-sm border text-center">
                <FormControl component="fieldset">
                    <RadioGroup
                        color="primary"
                        className="d-flex flex-column"
                        aria-label="gender"
                        name="tipo_despacho"
                        value={tipo_despacho}
                        onChange={(e) => {
                            setDatosVenta({
                                ...DatosVenta,
                                tipo_despacho: e.target.value,
                                ID_tipo_desp:"",
                                desp_porpagar_emp:"",
                                sucursal_id:""
                            });
                            setDatosNoSubmit({
                                total_despacho: 0,
                                peso_despacho: 0,
                                plazo_despacho: "",
                                productos_no_despachables: "",
                            });
                            setDirSucursalRetiroPrint("")
                            setErrorCampos({
                                ...ErrorCampos,
                                desp_porpagar_emp:{error:false,texto_error:""},
                                ID_tipo_desp:{error:false,texto_error:""}
                            })
                        }}
                    >
                        {FormDespacho.retiro ? (
                            <FormControlLabel
                                value="Retiro"
                                control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                label="Retiro en Tienda"
                                className="m-0"
                            />
                        ) : (
                            ""
                        )}
                        {FormDespacho.despacho ? (
                            <FormControlLabel
                                value="DespachoChilexpress"
                                control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                label="Despacho Chilexpress"
                                className="m-0"
                            />
                        ) : (
                            ""
                        )}
                        {FormDespacho.despacho ? (
                            <FormControlLabel
                                value="Despacho"
                                control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                label="Otros Despachos"
                                className="m-0"
                            />
                        ) : (
                            ""
                        )}
                    </RadioGroup>
                </FormControl>

                {tipo_despacho === "Retiro" && SucursalesRetiro && SucursalesRetiro.length>0? (
                    <div className="py-4 px-3 px-md-2">
                        <select    
                            className="form-control"
                            name="sucursal_id"
                            onChange={(e)=>{
                                setDatosVenta({
                                    ...DatosVenta,
                                    sucursal_id:e.target.value
                                })
                                if(e.target.value && e.target.value.length>0){
                                    const sucursalElegida = SucursalesRetiro.find((sucursal)=>sucursal.ID===e.target.value);
                                    setDirSucursalRetiroPrint(sucursalElegida.direccion||'');
                                }
                                else{
                                    setDirSucursalRetiroPrint("");
                                }                             
                            }}
                        >
                            <option 
                                value=""
                            >
                                Elija Sucursal para Retirar
                            </option>
                            {
                                SucursalesRetiro.map((sucursal,index)=>{
                                    return(
                                        <option key={index} value={sucursal.ID} data-direccion={sucursal.direccion}>
                                            {sucursal.nombre}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        {
                            ErrorCampos.sucursal_id.error?
                                <div>
                                    <Typography className="text-danger font-weight-bold" variant="body1">
                                        {ErrorCampos.sucursal_id.texto_error}
                                    </Typography>
                                </div>:""
                        }
                        {
                            DirSucursalRetiroPrint && DirSucursalRetiroPrint.length>0?
                            <div className="">
                                <Typography variant="body1" style={{color:EstilosGlobales.colorPrimario}} className="font-weight-bold mt-2">
                                    DIRECCIÓN RETIRO:
                                </Typography>
                                <Typography>
                                    {DirSucursalRetiroPrint}
                                </Typography>
                            </div>:""
                        }
                    </div>
                ) : (
                    ""
                )}
                {tipo_despacho === "Despacho" ? (
                    <div>
                        <SeleccionLocalidad
                            ErrorCamposPadre={{ ErrorCampos, setErrorCampos }}
                            Regiones={Regiones}
                            DatosVentaPadre={{ setDatosVenta, DatosVenta }}
                            DatosNoSubmitPadre={{
                                DatosNoSubmit,
                                setDatosNoSubmit,
                            }}
                            Region={{ Region, setRegion }}
                            LocalidadDisabled={{
                                LocalidadDisabled,
                                setLocalidadDisabled,
                            }}
                            Localidades={{ Localidades, setLocalidades }}
                        ></SeleccionLocalidad>

                        <TextField
                            name="direccion"
                            className="mt-3 mb-2"
                            value={DatosVenta.direccion}
                            onChange={(e) => {
                                setDatosVenta({
                                    ...DatosVenta,
                                    direccion: e.target.value,
                                });
                            }}
                            label="Dirección Despacho"
                            style={{ width: "60%" }}
                            error={ErrorCampos.direccion.error}
                            helperText={ErrorCampos.direccion.texto_error}
                        ></TextField>
                    </div>
                ) : (
                    ""
                )}
                {tipo_despacho === "DespachoChilexpress"? (
                    <FormChilexpress Propiedades={{setDatosVenta,DatosVenta,DatosNoSubmit,setDatosNoSubmit,DatosFormChilexpress,setDatosFormChilexpress,Sidenc}}></FormChilexpress>
                ) : (
                    ""
                )}
                {tipo_despacho !== "Retiro" && tipo_despacho !== "Despacho" && tipo_despacho!=="DespachoChilexpress" ? (
                    <div className="py-5">
                        <LinearProgressMine />
                    </div>
                ) : (
                    ""
                )}
                {Informacion.display ? (
                    <div className="px-1">
                        <Alert severity={Informacion.severity}>
                            <b>NOTA:</b> {Informacion.texto}
                        </Alert>
                    </div>
                ) : (
                    ""
                )}
            </div>
            <Typography className="font-weight-bold mt-4" variant="h6">
                Datos de la Compra
            </Typography>
            <div className="bg-white py-2 shadow-sm border text-center container">
                <div className="d-flex flex-row justify-content-center pt-3">
                    <FormControl component="fieldset">
                        <RadioGroup
                            color="primary"
                            className="d-flex flex-row"
                            aria-label="gender"
                            name="tipo"
                            value={DatosVenta.tipo}
                            onChange={(e) => {
                                if (e.target.value === "Boleta") {
                                    setDatosVenta({
                                        ...DatosVenta,
                                        tipo: e.target.value,
                                        razon_social: "",
                                        RUT_razon_social: "",
                                    });
                                } else {
                                    setDatosVenta({
                                        ...DatosVenta,
                                        tipo: e.target.value,
                                    });
                                }
                            }}
                        >
                            <FormControlLabel
                                value="Boleta"
                                control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                label="Boleta"
                            />
                            <FormControlLabel
                                value="Factura"
                                control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                label="Factura"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="row justify-content-center">
                    <div className={ClaseContTextField}>
                        <TextField
                            name="nombre"
                            className="w-100"
                            label="Nombre"
                            variant="outlined"
                            onChange={(e) => {
                                setDatosVenta({
                                    ...DatosVenta,
                                    nombre: e.target.value,
                                });
                            }}
                            value={DatosVenta.nombre}
                            error={ErrorCampos.nombre.error}
                            helperText={ErrorCampos.nombre.texto_error}
                        ></TextField>
                    </div>
                    <div className={ClaseContTextField}>
                        <TextField
                            name="RUT"
                            label="RUT"
                            variant="outlined"
                            className="w-100"
                            onChange={(e) => {
                                setErrorCampos({
                                    ...ErrorCampos,
                                    RUT: { error: false, texto_error: "" },
                                }); /* INI */
                                setDatosVenta({
                                    ...DatosVenta,
                                    RUT: clean(e.target.value),
                                });
                            }}
                            onBlur={(e) => {
                                var RUT_final = "";
                                RUT_final = e.target.value;
                                format(RUT_final);
                                if (validate(RUT_final)) {
                                    RUT_final = format(RUT_final);
                                    setDatosVenta({
                                        ...DatosVenta,
                                        RUT: RUT_final,
                                    });
                                } else {
                                    setDatosVenta({ ...DatosVenta, RUT: "" });
                                    setErrorCampos({
                                        ...ErrorCampos,
                                        RUT: {
                                            error: true,
                                            texto_error: "Rut inválido",
                                        },
                                    });
                                }
                            }}
                            value={DatosVenta.RUT}
                            error={ErrorCampos.RUT.error}
                            helperText={ErrorCampos.RUT.texto_error}
                        ></TextField>
                    </div>
                </div>
                {DatosVenta.tipo === "Factura" ? (
                    <div className="row justify-content-start">
                        <div className={ClaseContTextField}>
                            <TextField
                                name="razon_social"
                                label="Razon Social"
                                variant="outlined"
                                className="w-100"
                                onChange={(e) => {
                                    setDatosVenta({
                                        ...DatosVenta,
                                        razon_social: e.target.value,
                                    });
                                }}
                                value={DatosVenta.razon_social}
                                error={ErrorCampos.razon_social.error}
                                helperText={
                                    ErrorCampos.razon_social.texto_error
                                }
                            ></TextField>
                        </div>
                        <div className={ClaseContTextField}>
                            <TextField
                                name="RUT_razon_social"
                                label="RUT Razon Social"
                                variant="outlined"
                                className="w-100"
                                onChange={(e) => {
                                    setErrorCampos({
                                        ...ErrorCampos,
                                        RUT_razon_social: {
                                            error: false,
                                            texto_error: "",
                                        },
                                    }); /* INI */
                                    setDatosVenta({
                                        ...DatosVenta,
                                        RUT_razon_social: clean(e.target.value),
                                    });
                                }}
                                onBlur={(e) => {
                                    var RUT_final = "";
                                    RUT_final = e.target.value;
                                    format(RUT_final);
                                    if (validate(RUT_final)) {
                                        RUT_final = format(RUT_final);
                                        setDatosVenta({
                                            ...DatosVenta,
                                            RUT_razon_social: RUT_final,
                                        });
                                    } else {
                                        setDatosVenta({
                                            ...DatosVenta,
                                            RUT_razon_social: "",
                                        });
                                        setErrorCampos({
                                            ...ErrorCampos,
                                            RUT_razon_social: {
                                                error: true,
                                                texto_error: "Rut inválido",
                                            },
                                        });
                                    }
                                }}
                                value={DatosVenta.RUT_razon_social}
                                error={ErrorCampos.RUT_razon_social.error}
                                helperText={
                                    ErrorCampos.RUT_razon_social.texto_error
                                }
                            ></TextField>
                        </div>
                        <div className={ClaseContTextField}>
                            <TextField
                                name="direccion_facturacion"
                                label="Dirección Facturación"
                                variant="outlined"
                                className="w-100"
                                onChange={(e) => {
                                    setDatosVenta({
                                        ...DatosVenta,
                                        direccion_facturacion: e.target.value,
                                    });
                                }}
                                value={DatosVenta.direccion_facturacion}
                                error={ErrorCampos.direccion_facturacion.error}
                                helperText={
                                    ErrorCampos.direccion_facturacion.texto_error
                                }
                            ></TextField>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <div className="row justify-content-center">
                    <div className={ClaseContTextField}>
                        <TextField
                            name="telefono"
                            label="Teléfono"
                            variant="outlined"
                            className="w-100"
                            onChange={(e) => {
                                setDatosVenta({
                                    ...DatosVenta,
                                    telefono: e.target.value,
                                });
                            }}
                            value={DatosVenta.telefono}
                            error={ErrorCampos.telefono.error}
                            helperText={ErrorCampos.telefono.texto_error}
                        ></TextField>
                    </div>
                    <div className={ClaseContTextField}>
                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            className="w-100"
                            onChange={(e) => {
                                setDatosVenta({
                                    ...DatosVenta,
                                    email: e.target.value,
                                });
                            }}
                            value={DatosVenta.email}
                            error={ErrorCampos.email.error}
                            helperText={ErrorCampos.email.texto_error}
                        ></TextField>
                    </div>
                </div>
                <div className="d-flex flex-row px-4 justify-content-center mt-4">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={DatosVenta.segperson}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setDatosVenta({
                                            ...DatosVenta,
                                            segperson: true,
                                        });
                                    } else {
                                        setDatosVenta({
                                            ...DatosVenta,
                                            segperson_RUT: "",
                                            segperson_nombre: "",
                                            segperson: false,
                                        });
                                    }
                                }}
                                name="segperson"
                                color="primary"
                                label="asd"
                            />
                        }
                        label="¿Retira otra persona?"
                    />
                </div>
                {DatosVenta.segperson ? (
                    <div className="row justify-content-center">
                        <div className={ClaseContTextField}>
                            <TextField
                                name="segperson_nombre"
                                label="Nombre 2da persona"
                                variant="outlined"
                                className="w-100"
                                onChange={(e) => {
                                    setDatosVenta({
                                        ...DatosVenta,
                                        segperson_nombre: e.target.value,
                                    });
                                }}
                                value={DatosVenta.segperson_nombre}
                                error={ErrorCampos.segperson_nombre.error}
                                helperText={
                                    ErrorCampos.segperson_nombre.texto_error
                                }
                            ></TextField>
                        </div>
                        <div className={ClaseContTextField}>
                            <TextField
                                name="segperson_RUT"
                                label="RUT 2da persona"
                                variant="outlined"
                                className="w-100"
                                onChange={(e) => {
                                    setErrorCampos({
                                        ...ErrorCampos,
                                        segperson_RUT: {
                                            error: false,
                                            texto_error: "",
                                        },
                                    }); /* INI */
                                    setDatosVenta({
                                        ...DatosVenta,
                                        segperson_RUT: clean(e.target.value),
                                    });
                                }}
                                onBlur={(e) => {
                                    var RUT_final = "";
                                    RUT_final = e.target.value;
                                    format(RUT_final);
                                    if (validate(RUT_final)) {
                                        RUT_final = format(RUT_final);
                                        setDatosVenta({
                                            ...DatosVenta,
                                            segperson_RUT: RUT_final,
                                        });
                                    } else {
                                        setDatosVenta({
                                            ...DatosVenta,
                                            segperson_RUT: "",
                                        });
                                        setErrorCampos({
                                            ...ErrorCampos,
                                            segperson_RUT: {
                                                error: true,
                                                texto_error: "Rut inválido",
                                            },
                                        });
                                    }
                                }}
                                value={DatosVenta.segperson_RUT}
                                error={ErrorCampos.segperson_RUT.error}
                                helperText={
                                    ErrorCampos.segperson_RUT.texto_error
                                }
                            ></TextField>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

function SeleccionLocalidad(props) {
    //const [Region,setRegion] = useState("");
    //const [LocalidadDisabled,setLocalidadDisabled] = useState(true);
    let { LocalidadDisabled, setLocalidadDisabled } = props.LocalidadDisabled;
    //const [Localidades, setLocalidades] = useState([]);
    let { Localidades, setLocalidades } = props.Localidades;
    const [Estado, setEstado] = useState("cargado");
    const [Localidad, setLocalidad] = useState("");
    const [TiposDespachoLocalidad,setTiposDespachoLocalidad] = useState([]);
    const [EmpresasPorPagar,setEmpresasPorPagar] = useState([]);

    const MensajeCoberturaLocalidad = (
        <Typography className="font-weight-bold" variant="body2">
            <span className="text-danger">*</span> Estimado cliente,{" "}
            <span className="text-danger">
                la localidad se encuentra fuera de nuestra cobertura de despacho
                o el pedido supera los kilos permitidos.
            </span>{" "}
            <span className="text-danger">*</span>.{" "}
            <Link to="/coberturadespacho">Ver cobertura</Link>
        </Typography>
    );
    const MensajeCoberturaProductos = (
        <Typography className="font-weight-bold" variant="body2">
            <span className="text-danger">*</span> Estimado cliente,{" "}
            <span className="text-danger">
                los productos en el carro de compra no se encuentran disponibles
                para despacho.
            </span>{" "}
            <span className="text-danger">*</span>.
        </Typography>
    );

    const [MensajeCoberturaDespacho, setMensajeCoberturaDespacho] = useState({
        activo: false,
        mensaje: MensajeCoberturaLocalidad,
    });

    const handleCambioRegion = (e) => {
        var region_seleccionada = e.target.value;
        setRegion(e.target.value);
        setEstado("cargando");

        setLocalidad("");
        setTiposDespachoLocalidad([]);
        setDatosVenta({
            ...DatosVenta,
            ID_tipo_desp:"",
            desp_porpagar_emp:""
        })
        setMensajeCoberturaDespacho({
            mensaje: MensajeCoberturaLocalidad,
            activo: false,
        });
        setDatosNoSubmit({
            peso_despacho: 0,
            total_despacho: 0,
            plazo_despacho: "",
            productos_no_despachables: "",
        });

        var datos = new FormData();
        datos.append("region", region_seleccionada);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despdn/localidades_cargar.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            setLocalidades(res);
            setLocalidadDisabled(false);
            setEstado("cargado");
        });
    };

    const handleCambioLocalidad=(e)=>{
        var localidad_seleccionada = e.target.value;
        setLocalidad(e.target.value);
        setEstado("cargando");
        setTiposDespachoLocalidad([]);
        setEmpresasPorPagar([]);
        setMensajeCoberturaDespacho({
            mensaje:"",
            activo:false
        })
        setDatosVenta({
            ...DatosVenta,
            ID_tipo_desp:"",
            desp_porpagar_emp:"",
        })

        var datos=new FormData();
        datos.append("localidad", localidad_seleccionada);
        
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/despdn/cargar_tipo_despachos_localidad.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){
                setTiposDespachoLocalidad(res['tipo_desp_disponibles']);
                setEstado("cargado");
                setDatosVenta({
                    ...DatosVenta,
                    ciudad: localidad_seleccionada,
                    ID_tipo_desp:""
                });
            }else if(res[0]==="sin_tipodesp" || res[0]==="error_grp"){
                setTiposDespachoLocalidad([]);
                setEstado("cargado");
                setDatosVenta({
                    ...DatosVenta,
                    ciudad: localidad_seleccionada,
                    ID_tipo_desp:"",
                    ciudad:""
                });
                setLocalidad("");
                setMensajeCoberturaDespacho({
                    activo:true,
                    mensaje:
                        <div className="w-100 d-flex flex-row justify-content-center">
                            <Typography className="text-danger font-weight-bold">
                                Ciudad sin Cobertura de Despacho
                            </Typography>
                        </div>
                })
            }
            
        })
    }

    const handleCambioTipoDespacho= (e) => {
        var ID_tipo_desp_seleccionado= e.target.value;
        setEstado("cargando");
        setDatosVenta({
            ...DatosVenta,
            ID_tipo_desp:ID_tipo_desp_seleccionado
        })

        var datos= new FormData();

        datos.append("ciudad", DatosVenta.ciudad);
        datos.append("ID_tipo_desp",ID_tipo_desp_seleccionado);

        //console.log("Intentando con ID_tipo_desp:"+ID_tipo_desp_seleccionado);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/despdn/calc_despdn.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "calculado") {
                setEstado("cargado");
                setDatosNoSubmit({
                    peso_despacho: res["peso_total_prods"],
                    total_despacho: res["total_desp_productos"],
                    plazo_despacho: res["plazo_despacho"],
                    productos_no_despachables:
                        res["productos_no_despachables"],
                });
                if(res['empresas_porpagar'] && res['empresas_porpagar'].length>0){
                    setEmpresasPorPagar(res['empresas_porpagar']);
                }else{
                    setEmpresasPorPagar([]);
                }
                if (res["productos_no_despachables"].length > 0) {
                    setMensajeCoberturaDespacho({
                        mensaje: (
                            <Typography variant="body2">
                                <b className="text-danger">*</b>{" "}
                                <b className="text-danger">
                                    Estimado cliente: el carro de
                                    compras contiene los siguiente
                                    productos no disponibles para
                                    despacho:
                                </b>{" "}
                                <br></br>
                                {res["productos_no_despachables"].map(
                                    (producto, index) => {
                                        return (
                                            <span key={index}>
                                                <b>- {producto.SKU}:</b>{" "}
                                                {producto.nombre}
                                                <br></br>
                                            </span>
                                        );
                                    }
                                )}
                                <br></br>
                                <b>
                                    Si continúa la compra, estos
                                    productos{" "}
                                    <u>deberá retirarlos en el local</u>
                                </b>
                            </Typography>
                        ),
                        activo: true,
                    });
                } else {
                    setMensajeCoberturaDespacho({
                        mensaje: "",
                        activo: false,
                    });
                }
            } else if (res[0] === "ciudad_no_despachable") {
                setLocalidad("");
                setMensajeCoberturaDespacho({
                    mensaje: MensajeCoberturaLocalidad,
                    activo: true,
                });
                setDatosNoSubmit({
                    peso_despacho: 0,
                    total_despacho: 0,
                    plazo_despacho: "",
                    productos_no_despachables: "",
                });
                setEstado("cargado");
            } else if (res[0] === "productos_no_despachables") {
                setLocalidad("");
                setMensajeCoberturaDespacho({
                    mensaje: MensajeCoberturaProductos,
                    activo: true,
                });
                setDatosNoSubmit({
                    peso_despacho: 0,
                    total_despacho: 0,
                    plazo_despacho: "",
                    productos_no_despachables: "",
                });
                setEstado("cargado");
            }
        });
    };

    let { ErrorCampos } = props.ErrorCamposPadre;
    let { Regiones } = props;
    let { setDatosVenta, DatosVenta } = props.DatosVentaPadre;
    let { setDatosNoSubmit } = props.DatosNoSubmitPadre;
    let { Region, setRegion } = props.Region;
    return (
        <div className="container">
            <div className="row px-3">
                <div className="col-md-6">
                    <select
                        value={Region}
                        onChange={(e) => {
                            handleCambioRegion(e);
                        }}
                        className="form-control my-2"
                    >
                        <option value="">Seleccione Región</option>
                        {Regiones.map((region, index) => {
                            return (
                                <option key={index} value={region.region}>
                                    {region.nombre}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-md-6">
                    {LocalidadDisabled ? (
                        <select disabled className="form-control my-2">
                            <option value="">Seleccione Localidad</option>
                        </select>
                    ) : (
                        <select
                            name="ciudad"
                            value={Localidad}
                            onChange={(e) => {
                                handleCambioLocalidad(e);
                            }}
                            className="form-control my-2"
                        >
                            <option value="">Seleccione Localidad</option>
                            {Localidades.map((localidad, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={localidad.nombre}
                                    >
                                        {localidad.nombre}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                </div>
            </div>
            <div className="row justify-content-center px-3">
                <FormControl component="fieldset">
                    <RadioGroup
                        color="primary"
                        className=""
                        aria-label="gender"
                        name="ID_tipo_desp"
                        value={DatosVenta.ID_tipo_desp}
                        onChange={(e)=>{
                            handleCambioTipoDespacho(e);
                        }}
                    >
                        {
                            TiposDespachoLocalidad.map((tipoDesp,index)=>{
                                return(
                                    <FormControlLabel
                                        key={index}
                                        value={tipoDesp.ID_tipo_despacho}
                                        control={<Radio style={{ color: EstilosGlobales.colorPrimario }} />}
                                        label={tipoDesp.nombre}
                                        className="py-0 my-0"
                                    />
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </div>
            {
                ErrorCampos.ID_tipo_desp.error?
                <div className="row justify-content-center px-3">
                    <Typography variant="body2" className="text-danger font-weight-bold"> 
                        * {ErrorCampos.ID_tipo_desp.texto_error} * 
                    </Typography> 
                </div>:""
            }

            {
                EmpresasPorPagar && EmpresasPorPagar.length>0?
                <div className="row justify-content-center px-3">
                    <div className="">
                        <select value={DatosVenta.desp_porpagar_emp} className="form-control"
                            onChange={(e)=>{
                                setDatosVenta({
                                    ...DatosVenta,
                                    desp_porpagar_emp:e.target.value
                                })
                            }}
                            name="desp_porpagar_emp"
                        >
                            <option value="">Seleccione Empresa</option>
                            {
                                EmpresasPorPagar.map((empresa,index)=>{
                                    return(
                                        <option key={index} value={empresa.nombre}>
                                            {empresa.nombre}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        {
                            ErrorCampos.desp_porpagar_emp.error?
                            <Typography variant="body2" className="font-weight-bold text-danger">
                                * {ErrorCampos.desp_porpagar_emp.texto_error} *
                            </Typography>:""
                        }
                    </div>
                </div>:""
            }

            {/*<span className="btn btn-success" onClick={()=>{console.log(DatosVenta)}}>
                Corroborar
            </span>*/}

            {Estado !== "cargado" ? (
                <div className="mt-2">
                    <LinearProgressMine className="mt-2"></LinearProgressMine>
                </div>
            ) : (
                ""
            )}
            {MensajeCoberturaDespacho.activo ? (
                <div className="row px-5 mt-2">
                    {MensajeCoberturaDespacho.mensaje}
                </div>
            ) : (
                ""
            )}
            {ErrorCampos.ciudad.error ? (
                <div className="row px-5 mt-2 justify-content-center">
                    <span className="font-weight-bold text-danger">
                        {ErrorCampos.ciudad.texto_error}
                    </span>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
