import React, { useState, useEffect,useContext,useRef,useCallback } from "react";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FacebookIcon from "@material-ui/icons/Facebook";
/*import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";*/
import InstagramIcon from "@material-ui/icons/Instagram";
import axios from "axios";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReCAPTCHA from "react-google-recaptcha";
import LinearProgressMine from '../LinearProgressMine'
import DatosConfigContext from '../../context/config'
//META
import MetaData from '../../context/MetaData';
import EstilosGlobales from "../../toremp/EstilosGlobales";
import {useParams} from 'react-router-dom'
import ConfigSecciones from '../../toremp/ConfigSecciones'
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


function ContenidoContactoChildren({gtoken,handleReCaptchaVerify}) {
    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Contacto" }];
    const {DatosConfig} = useContext(DatosConfigContext);
    let {accion} = useParams();
    const seccionSucursales = useRef(null);

    const [DatosForm, setDatosForm] = useState({
        nombre: "",
        email: "",
        telefono: "",
        asunto: "",
        mensaje: "",
        ciudad: "",
    });
    const [ErrorCampos, setErrorCampos] = useState({
        nombre: { error: false, texto: "" },
        email: { error: false, texto: "" },
        telefono: { error: false, texto: "" },
        asunto: { error: false, texto: "" },
        mensaje: { error: false, texto: "" },
        gcaptcha: { error: false, texto: "" },
        ciudad: { error: false, texto: "" },
    });


    const [Estado, setEstado] = useState("inicio");
    
    const DatosEmpresa = {
        ...(DatosConfig?.Empresa||{})
    };

    const [GCaptchaConfig, setGCaptchaConfig] = useState({
        cargado: false,
        gsite_key: "",
    });


    useEffect(()=>{
        /*const corroborarEmailParam=()=>{
            if(email_contacto && email_contacto.length>0){
                setDatosForm({...DatosForm,email_contacto});
            }        
        } 
        */
        const navegarScrollSucursales=()=>{
            seccionSucursales.current.scrollIntoView(true);
        }

        if(accion && accion==="sucursales"){
            navegarScrollSucursales();
        }

        //corroborarEmailParam();
    },[]);


    const [EstadoForm, setEstadoForm] = useState("inicio");
    const handleSubmitForm = async(e) => {
        e.preventDefault();
        var ErrorCamposIns = {
            nombre: { error: false, texto: "" },
            email: { error: false, texto: "" },
            telefono: { error: false, texto: "" },
            asunto: { error: false, texto: "" },
            mensaje: { error: false, texto: "" },
            gcaptcha: { error: false, texto: "" },
            ciudad: { error: false, texto: "" }
        };
        var campos_requeridos = 0;

        if (DatosForm.nombre.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                nombre: { error: true, texto: "Campo Requerido" },
            };
        }
        if (DatosForm.email.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                email: { error: true, texto: "Campo Requerido" },
            };
        }
        if (DatosForm.telefono.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                telefono: { error: true, texto: "Campo Requerido" },
            };
        }
        if (DatosForm.ciudad.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                ciudad: { error: true, texto: "Campo Requerido" },
            };
        }
        if (DatosForm.asunto.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                asunto: { error: true, texto: "Campo Requerido" },
            };
        }
        if (DatosForm.mensaje.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                mensaje: { error: true, texto: "Campo Requerido" },
            };
        }

        if (gtoken.length <= 0) {
            campos_requeridos += 1;
            ErrorCamposIns = {
                ...ErrorCamposIns,
                gcaptcha: { error: true, texto: "Espera a que cargue el captcha" },
            };
        }

        if (campos_requeridos > 0) {
            setErrorCampos(ErrorCamposIns);
            return false;
        } else {
            setErrorCampos(ErrorCamposIns);
        }

        //CAPA GCaptcha
        var datos = new FormData();
        await handleReCaptchaVerify();
        datos.append("g-recaptcha-response", gtoken);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/googleCaptcha/validar_captcha.php",
            method: "post",
            data: datos,
        })
        .then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {

                if (EstadoForm === "cargando") {
                    return false;
                } else {
                    setEstadoForm("cargando");
                }

                var datos = new FormData();
                datos.append("nombre", DatosForm.nombre);
                datos.append("email", DatosForm.email);
                datos.append("telefono", DatosForm.telefono);
                datos.append("asunto", DatosForm.asunto);
                datos.append("mensaje", DatosForm.mensaje);
                datos.append("ciudad", DatosForm.ciudad);
                axios({
                    url: process.env.PUBLIC_URL + "/assets/api/contacto_submit.php",
                    method: "post",
                    data: datos,
                }).then((resp) => {
                    var res = resp.data;
                    //console.log(res);
                    if (res[0] === "realizado") {
                        setEstadoForm("enviado");
                    }
                });
            } else if (res[0] === "error_rechazado") {
                setErrorCampos({
                    ...ErrorCampos,
                    gcaptcha: {
                        error: true,
                        texto: "Captcha inválido, reingrese.",
                    },
                });
                return false;
            }
        });
    };

    /*useEffect(() => {
        const cargarCaptcha = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/googleCaptcha/cargar_captcha.php",
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setGCaptchaConfig({
                        cargado: true,
                        gsite_key: res["gsite_key"],
                    });
                }
            });
        };

        cargarCaptcha();
    }, []);*/


//    const submitCaptcha = () => {
//                
//    };
    const ClaseTextFieldCont = "mt-3 d-flex flex-row align-items-center";
    return (
        <div>
            <MetaData titulo="Contacto"/>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo titulo="Contacto"></Titulo>
                    <div className="row py-4">
                        {
                            ConfigSecciones.contacto.redes_sociales.activado && ConfigSecciones.contacto.redes_sociales.activado==="true"?
                            <RedesSociales DatosEmpresa={DatosEmpresa}/>:""
                        }

                        <div className="col-md-6">
                            <div
                                className="bg-white shadow-sm rounded border py-2 px-1 px-md-5"
                                style={{ minHeight: "400px" }}
                            >
                                {EstadoForm !== "enviado" ? (
                                    <form
                                        onSubmit={(e) => {
                                            handleSubmitForm(e);
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            className="font-weight-bold text-center"
                                        >
                                            Formulario de Contacto
                                        </Typography>
                                        <div className={ClaseTextFieldCont}>
                                            <TextField
                                                variant="outlined"
                                                label="Nombre"
                                                size="small"
                                                className="w-100"
                                                onChange={(e) => {
                                                    setDatosForm({
                                                        ...DatosForm,
                                                        nombre: e.target.value,
                                                    });
                                                }}
                                                error={ErrorCampos.nombre.error}
                                                helperText={
                                                    ErrorCampos.nombre.texto
                                                }
                                            ></TextField>
                                            <CampoRequeridoPrint/>
                                        </div>
                                        <div className={ClaseTextFieldCont}>
                                            <TextField
                                                variant="outlined"
                                                label="Email"
                                                size="small"
                                                className="w-100"
                                                onChange={(e) => {
                                                    setDatosForm({
                                                        ...DatosForm,
                                                        email: e.target.value,
                                                    });
                                                }}
                                                error={ErrorCampos.email.error}
                                                helperText={
                                                    ErrorCampos.email.texto
                                                }
                                                value={DatosForm.email}
                                            ></TextField>
                                            <CampoRequeridoPrint/>
                                        </div>
                                        <div className={ClaseTextFieldCont}>
                                            <TextField
                                                variant="outlined"
                                                label="Teléfono"
                                                size="small"
                                                className="w-100"
                                                onChange={(e) => {
                                                    setDatosForm({
                                                        ...DatosForm,
                                                        telefono:
                                                            e.target.value,
                                                    });
                                                }}
                                                error={
                                                    ErrorCampos.telefono.error
                                                }
                                                helperText={
                                                    ErrorCampos.telefono.texto
                                                }
                                            ></TextField>
                                            <CampoRequeridoPrint/>
                                        </div>
                                        <div className={ClaseTextFieldCont}>
                                            <TextField
                                                variant="outlined"
                                                label="Ciudad"
                                                size="small"
                                                className="w-100"
                                                onChange={(e) => {
                                                    setDatosForm({
                                                        ...DatosForm,
                                                        ciudad:
                                                            e.target.value,
                                                    });
                                                }}
                                                error={
                                                    ErrorCampos.ciudad.error
                                                }
                                                helperText={
                                                    ErrorCampos.ciudad.texto
                                                }
                                            ></TextField>
                                            <CampoRequeridoPrint/>
                                        </div>
                                        <div className={ClaseTextFieldCont}>
                                            <TextField
                                                variant="outlined"
                                                label="Asunto"
                                                size="small"
                                                className="w-100"
                                                onChange={(e) => {
                                                    setDatosForm({
                                                        ...DatosForm,
                                                        asunto: e.target.value,
                                                    });
                                                }}
                                                error={ErrorCampos.asunto.error}
                                                helperText={
                                                    ErrorCampos.asunto.texto
                                                }
                                            ></TextField>
                                            <CampoRequeridoPrint/>
                                        </div>
                                        <div className="">
                                            <div className={ClaseTextFieldCont}>
                                                <textarea
                                                    onChange={(e) => {
                                                        setDatosForm({
                                                            ...DatosForm,
                                                            mensaje: e.target.value,
                                                        });
                                                    }}
                                                    rows="4"
                                                    className="form-control"
                                                    placeholder="Tu mensaje..."
                                                ></textarea>
                                                <CampoRequeridoPrint/>
                                            </div>
                                            {ErrorCampos.mensaje.error ? (
                                                <Typography className="font-weight-bold text-danger">
                                                    * Campo Requerido *
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        {/*<div className="mt-2 d-flex justify-content-center">
                                            <div id='gcaptcha-container'
                                            ></div>
                                        </div>*/}

                                        {ErrorCampos.gcaptcha.error ? (
                                            <div className="mb-1">
                                                <Typography
                                                    variant="body2"
                                                    className="text-danger font-weight-bold"
                                                >
                                                    *{" "}
                                                    {ErrorCampos.gcaptcha.texto}{" "}
                                                    *
                                                </Typography>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="my-3 text-center">
                                            <div className="d-flex flex-row justify-content-center">
                                                <Button
                                                    type="submit"
                                                    style={{
                                                        backgroundColor:
                                                        EstilosGlobales.colorPrimario
                                                    }}
                                                    variant="contained"
                                                    className="text-white font-weight-bold"
                                                    size="large"
                                                >
                                                    Enviar
                                                </Button>
                                                {EstadoForm === "cargando" ? (
                                                    <CircularProgress
                                                        className="ml-2"
                                                        style={{
                                                            color: EstilosGlobales.colorPrimario,
                                                        }}
                                                    ></CircularProgress>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <div className="d-flex flex-row justify-content-center align-items-center h-100">
                                        <div className="mt-5 d-flex flex-row align-items-center">
                                            <CheckCircleIcon
                                                fontSize="large"
                                                className="text-success font-weight-bold"
                                            ></CheckCircleIcon>
                                            <Typography
                                                className="ml-2"
                                                variant="h5"
                                            >
                                                Formulario Enviado
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-white shadow-sm rounded border py-2 px-1 px-md-5">
                                <Typography
                                    variant="h6"
                                    className="font-weight-bold text-center"
                                >
                                    ¿D&oacute;nde encontrarnos?
                                </Typography>
                                <div className="my-2">
                                    {
                                        DatosEmpresa.email && DatosEmpresa.email.length>0 && false?
                                        <div>
                                            {
                                                DatosEmpresa.email.map((email,index)=>{
                                                    return(
                                                        <div key={index} className="d-flex flex-row my-2">
                                                            <MailOutlineIcon></MailOutlineIcon>
                                                            <Typography
                                                                className="ml-2"
                                                                variant="body1"
                                                            >
                                                                {/*DatosEmpresa.email*/}
                                                                {email}
                                                            </Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>:""
                                    }
                                    {
                                        DatosEmpresa.telefono && DatosEmpresa.telefono.length>0 && false?
                                        <div>
                                            {
                                                DatosEmpresa.telefono.map((telefono,index)=>{
                                                    return(
                                                        <div key={index} className="d-flex flex-row my-2">
                                                            <PhoneIcon></PhoneIcon>
                                                            <Typography
                                                                className="ml-2"
                                                                variant="body1"
                                                            >
                                                                {/*DatosEmpresa.telefono*/}
                                                                {telefono}
                                                            </Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>:""
                                    }
                                    {
                                        /*
                                        <div className="d-flex flex-row my-2">
                                            <LocationOnIcon></LocationOnIcon>
                                            <Typography
                                                className="ml-2"
                                                variant="body1"
                                            >
                                                {DatosEmpresa.direccion}
                                            </Typography>
                                        </div>
                                        
                                        */
                                    }
                                    <div className="d-flex flex-row mt-0 border rounded shadow-sm">
                                        {
                                            DatosConfig && DatosConfig.API_config.googleMaps.link?
                                            <iframe
                                                title="emp_map"
                                                src={DatosConfig.API_config.googleMaps.link}
                                                width="600"
                                                height="300"
                                                style={{ border: "0px" }}
                                                allowFullScreen
                                                loading="lazy"
                                            ></iframe>
                                            :
                                            <div className="d-flex flex-row justify-content-center align-items-center" style={{height:"300px"}}>
                                                <div className="">
                                                    <LinearProgressMine />
                                                    <Typography>
                                                        Cargando...
                                                    </Typography>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                    DatosConfig && DatosConfig.Empresa.sucursales && false?
                    <>
                        <Titulo>Sucursales</Titulo> 
                        <div ref={seccionSucursales} className="row" id="sucursales">
                            {
                                DatosConfig.Empresa.sucursales.map((sucursal,index)=>{
                                    return(
                                        <div className="col-md-4 text-center my-2" key={index}>
                                            <div className="bg-white border rounded shadow-sm py-2">
                                                <Typography className="font-weight-bold" variant="h6">
                                                    {sucursal.nombre}
                                                </Typography>
                                                {
                                                    sucursal.direccion && sucursal.direccion.length>0?
                                                    <div className="d-flex flex-row align-items-center justify-content-center">
                                                        <LocationOnIcon className="mr-1" style={{color:EstilosGlobales.colorPrimario}}/> 
                                                        <Typography className="" variant="body1">
                                                            {sucursal.direccion}
                                                        </Typography>
                                                    </div>:""
                                                }
                                                {
                                                    sucursal.telefono.map((numero,index)=>{
                                                        return(
                                                            <div key={index} className="d-flex flex-row align-items-center justify-content-center">
                                                                <PhoneIcon className="mr-1" style={{color:EstilosGlobales.colorPrimario}}/> 
                                                                <a className="text-decoration-none" href={"tel:"+numero}>
                                                                    <Typography className="" variant="body1">
                                                                        {numero}
                                                                    </Typography>
                                                                </a>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    sucursal.googleMaps && sucursal.googleMaps.length>0?
                                                    <div>
                                                        <iframe
                                                            title="emp_map"
                                                            src={sucursal.googleMaps}
                                                            width="220"
                                                            height="300"
                                                            style={{ border: "0px" }}
                                                            allowFullScreen
                                                            loading="lazy"
                                                        ></iframe>
                                                    </div>:""
                                                }
                                            </div> 
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                    :""
                    }
                </div>
            </div>
        </div>
    );
}

function LineaVertical() {
    const EstilosLinea = {
        height: "100%",
        width: "1px",
    };
    return <div className={"mr-1 border-right"} style={EstilosLinea}></div>;
}

function RedesSociales(props){
    
    let {DatosEmpresa} = props;
    const clasesIconos = "mr-1";
    return(
        <div className="col-12">
            <div className="bg-white shadow-sm rounded border pt-2 pb-3 px-1 px-md-5 mb-2">
                <Typography
                    variant="h6"
                    className="font-weight-bold text-center"
                >
                    Nuestras Redes
                </Typography>
                <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={DatosEmpresa.facebook}
                        className={clasesIconos}
                    >
                        <FacebookIcon
                            fontSize="large"
                            style={{ color: "#2D88FF" }}
                        ></FacebookIcon>
                    </a>
                    <LineaVertical></LineaVertical>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={DatosEmpresa.instagram}
                        className={clasesIconos}
                    >
                        <InstagramIcon
                            fontSize="large"
                            style={{ color: "#ED4956" }}
                        ></InstagramIcon>
                    </a>
                </div>
            </div>
        </div>
    )
}

function CampoRequeridoPrint(){
    return(
        <div className="ml-1">
            <Typography variant="body1" className="text-danger font-weight-bold">
                (*)
            </Typography>
        </div>

    )
}

const ContenidoContacto=()=>{
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [gtoken,setGtoken] = useState('');

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const getTokenResult = await executeRecaptcha('yourAction');

        if(getTokenResult){
            setGtoken(getTokenResult);
        }
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return(
        <ContenidoContactoChildren handleReCaptchaVerify={handleReCaptchaVerify} gtoken={gtoken} />
    )
}


export default ContenidoContacto;