import React,{useState} from 'react'
import axios from 'axios'

const CyberModeContext = React.createContext();

export function CyberModeProvider(props){
    const [CyberMode,setCyberMode] = useState(false);
    const [DatosCyberMode,setDatosCyberMode] = useState({
        cyber_logo:"#",
        cyber_etiqueta_productos:"#",
        cyber_nombre:""
    });

    
    const comprobarCyberMode=()=>{
        var datos = new FormData();
        datos.append("accion","load_cyber_data");

        axios({
            url: process.env.PUBLIC_URL + "/assets/api/cyber/cyber_comprobar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){
                if(res['cyber_mode']) {
                    if(!CyberMode){
                        setCyberMode(true);
                        setDatosCyberMode({
                            cyber_logo:res['cyber_logo'],
                            cyber_etiqueta_productos:res['cyber_etiqueta_productos'],
                            cyber_nombre:res['cyber_nombre']
                        });
                    }
                }else{
                    if(CyberMode){
                        setCyberMode(false);
                    }
                }

            }else{
                if(CyberMode){
                    setCyberMode(false);
                }
            }
        })
    }



    return(
        <CyberModeContext.Provider value={{CyberMode,setCyberMode,DatosCyberMode,setDatosCyberMode,comprobarCyberMode}}>
            {props.children}
        </CyberModeContext.Provider>
    );
}

export default CyberModeContext;

