import { useEffect, useState } from "react";
import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import Ruta from "../components/Ruta";
import Titulo from "../components/Titulo";
import { Typography } from "@material-ui/core";
import axios from "axios";
//META
import MetaData from "../context/MetaData";
import { useParams, useHistory } from "react-router-dom";
import EstilosGlobales from '../toremp/EstilosGlobales.jsx'
import LinkIcon from '@mui/icons-material/Link';

export default function Acercaden() {
    let history = useHistory();

    const { seccion } = useParams();

    const [Rutas, setRutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Empresa" },
    ]);

    const [Contenido, setContenido] = useState({});
    const [CargadoContenido, setCargadoContenido] = useState();

    useEffect(() => {
        setCargadoContenido(false);
        axios({
            url:
                process.env.PUBLIC_URL +
                "/toremp/contenido/index.json",
        }).then((resp) => {
            var res = resp.data;
            var seccion_index_encontrada = {};
            res.secciones.map((seccion_index) => {
                if (seccion_index.ruta === seccion) {
                    seccion_index_encontrada = seccion_index;
                    return 1;
                }else return 0
            });
            if (seccion_index_encontrada.contenido) {
                axios({
                    url:
                        process.env.PUBLIC_URL +
                        "/toremp/contenido/" +
                        seccion_index_encontrada.contenido,
                }).then((resp) => {
                    var res = resp.data;
                    if (res) {
                        setContenido(res);
                        setCargadoContenido(true);
                        setRutas([
                            { nombre: "Inicio", ruta: "/" },
                            { nombre: "Empresa" },
                            { nombre: res.titulo },
                        ]);
                    }
                });
            } else {
                history.push("/");
            }
        });
    }, [seccion]);

    if (!CargadoContenido) {
        return <div className="d-flex flex-row justify-content-center"></div>;
    } else {
        return (
            <div>
                <MetaData titulo={Contenido.titulo}></MetaData>
                <Header></Header>
                <Ruta ruta={Rutas}></Ruta>
                <div
                    className="container"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo className="py-3" titulo={Contenido.titulo}></Titulo>
                    {
                        Contenido.contenido.map((contenido_seccion,index)=>{
                            return(
                                <ContenidoSeccion key={index} contenido={contenido_seccion} />
                            )
                        })
                        
                    }
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

function ContenidoSeccion(props) {
    let {contenido} = props;

    var claseParrafos = "col-md-7";
    var claseImagenes= "col-md-5";
    var claseRow = "row pt-3 pb-5";

    if(contenido.formato.sentido==="reverse"){
        claseRow = "row flex-row-reverse pt-3 pb-5";
    }

    if(contenido.formato.columna==="12"){
        claseParrafos = "col-md-12";
        claseImagenes= "col-md-12";
    }
    

    return (
    <div className="">
        <div className={claseRow}>
            <div className={claseParrafos}>
                {
                    contenido.subtitulo?
                        <Typography className="text-justify py-1 px-2 text-white rounded mb-2" variant="h6" style={{background:EstilosGlobales.colorSecundario}}>
                            {contenido.subtitulo} 
                        </Typography>
                    :""
                }
                {
                    contenido.parrafos.map((parrafo,index)=>{
                        return(
                            <Typography className="text-justify" key={index}>
                                {parrafo.p} 
                                <br></br>
                            </Typography>
                        )
                    })
                }
                <>
                    {
                        contenido.links && contenido.links.length>0?
                        <div className="mt-2">
                            {
                                contenido.links.map((link,index)=>{
                                    return(
                                        <a className="btn text-white font-weight-bold" href={link.link} target="_blank" key={index} style={{backgroundColor:EstilosGlobales.colorSecundario}} rel="noreferrer">
                                            <div className="d-flex flex-row">
                                                <LinkIcon className="mr-2"/>
                                                <span>
                                                    {link.titulo}
                                                </span>
                                            </div>
                                        </a>
                                    )
                                })
                            }
                        </div>:""
                    }
                </>
            </div>
            <div className={claseImagenes}>
                <div className={contenido.formato.imagenes.clase_contenedor}>
                    {
                        contenido.imagenes.map((imagen,index)=>{
                            return(
                                <div className={contenido.formato.imagenes.clase_imagenes} key={index}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/toremp/imagenes/"+imagen
                                        }
                                        alt="sup"
                                        className="img-fluid rounded shadow-sm mt-2"
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
    );
}
