import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useConfigContext } from '../context/config';

export default function Whatsapp(){

    const paddingY = "10px";
    const Estilos = {
        bottom:"20px",
        right:"20px",
        zIndex:"2000"       
    }

    const EstilosBoton = {
        paddingTop:paddingY,
        paddingBottom:paddingY,
        width:'70px',
        height:'70px'
    }

    const {DatosConfig} = useConfigContext();

    return(
        <div className="position-fixed" style={Estilos}>
            {
                DatosConfig && 
                <a target="_blank" rel="noreferrer" href={DatosConfig?.Empresa?.whatsapp}>
                    <button style={EstilosBoton} className="btn btn-success rounded-circle shadow">
                        <WhatsAppIcon style={{fontSize:'45px'}} fontSize="large" className="text-white"></WhatsAppIcon>
                    </button>
                </a>
            }
        </div>
    );
}
