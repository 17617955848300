import React,{useState,useEffect} from 'react'
import axios from 'axios'

const DatosConfigContext= React.createContext();

export function DatosConfigProvider(props){

    const [DatosConfig,setDatosConfig] = useState();
    const [Cargado,setCargado] = useState(false);
    
    const cargarDatosConfig=()=>{
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/config_g.php",
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            //console.log(res.API_config.googleAnalytics.trackingId);
            if(res){
                setDatosConfig(res);
                setCargado(true);
            }
        })  
    }

    useEffect(()=>{
        cargarDatosConfig();
    },[])

    if(Cargado){
        return(
            <DatosConfigContext.Provider value={{DatosConfig}}>
                {props.children}
            </DatosConfigContext.Provider>
        );
    }else{
        return(
            <></>
        );
    }

}

export const useConfigContext=()=>React.useContext(DatosConfigContext);

export default DatosConfigContext;

