import { useState,useEffect,useContext } from "react";
import Ruta from '../Ruta';
import CarruselImagenes from './CarruselImagenes'
import axios from "axios";
import { useParams } from "react-router";
import DatosProductoPrincipal from './DatosProductoPrincipal'
import Carro from '../../context/Carro'
import {Redirect,useHistory} from 'react-router-dom'
import Swal from '../../context/SwalAlert'
import ProductosRelacionados from "./ProductosRelacionados";
//META
import MetaData from '../../context/MetaData'
import EstilosGlobales from "../../toremp/EstilosGlobales";
//TOREMP
import ProductoEspecificacionContenido from '../../toremp/components/ProductoEspecificacionContenido.jsx'
import ConfigModulos from '../../toremp/ConfigModulos'

export default function ContenidoProductoVer(){
    let history = useHistory();
    let {SKU} = useParams();

    const [Rutas,setRutas] = useState([
        {nombre:"Inicio",ruta:"/"},
        {nombre:"Cargando..."}
    ]);

    const [Estado,setEstado] = useState("inicio");
    const [DatosProducto,setDatosProducto] = useState({
        SKU:"",
        productos_esp_index:[]
    });
    const [Cantidad,setCantidad] = useState(1);

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarDatosProducto();
        }else if(SKU!==DatosProducto.SKU && Estado==="cargado"){
            cargarDatosProducto();
        }
    })

    const cargarDatosProducto=()=>{
        setEstado("cargando");
        var datos = new FormData();
        datos.append("SKU",SKU);
        datos.append("accion","cargar");        

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/productover_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res); 
            if(res[0]==="realizado"){
                setDatosProducto(res['datos_producto']);
                setEstado("cargado");
                setRutas([
                    {nombre:"Inicio",ruta:"/"},
                    {nombre:res['datos_producto'].superfamilia,ruta:"/superfamilias/"+encodeURIComponent(res['datos_producto'].superfamilia)},
                    {nombre:res['datos_producto'].familia},
                    {nombre:res['datos_producto'].subfamilia,ruta:"/productos/"+encodeURIComponent(res['datos_producto'].superfamilia)+"/"+encodeURIComponent(res['datos_producto'].familia)+"/"+encodeURIComponent(res['datos_producto'].subfamilia)+"/subfamilia"},
                    {nombre:res['datos_producto'].nombre}
                ]);
            }else{
                /* Redirect */
                history.push("/");
            }
        })
        
    }

    /* const AgregarCarro=()=>{
        console.log("Agregando: "+Cantidad+" del producto "+DatosProducto.SKU);
    } */
    /* CartDN */
    const [RedirigirCarro,setRedirigirCarro] = useState(false);
    const {ActualizarCarro} = useContext(Carro);
    const [EstadoAgregarCarro,setEstadoAgregarCarro] = useState("cargado");
    const AgregarCarro=()=>{
        if(DatosProducto.cotizar && parseInt(DatosProducto.cotizar)>0){
            Swal.fire({
                title:"Agregar Carro",
                html:"El Producto no puede ser agregado al carro, ya que esta en modo cotizar.",
                icon:"warning",
                confirmButtonText:"Aceptar",
                confirmButtonColor:EstilosGlobales.colorSecundario,
                position: 'top-end'
            })
            return false;
        }

        if(EstadoAgregarCarro==="cargando"){
            return false;
        }
        setEstadoAgregarCarro("cargando");
        var SKU = DatosProducto.SKU;
        var cantidad = 1;
        if(Cantidad>1){
            cantidad=Cantidad;
        }
        var datos = new FormData();

        datos.append("SKU",SKU);
        datos.append("cantidad",cantidad);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_agregar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            /* console.log(res); */
            var html_body = "";
            
            if(res[0]==="realizado"){
                html_body = <div>El producto fue agregado <b>Satisfactoriamente al carro de compras</b>.</div>;
                Swal.fire({
                    title:"Producto Agregado",
                    html:html_body,
                    icon:"success",
                    showCancelButton:true,
                    cancelButtonText:"Aceptar",
                    cancelButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonColor:EstilosGlobales.colorPrimario,
                    position: 'top-end',
                    confirmButtonText:"Ver Carro"
                    
                }).then((result)=>{
                    if(result.value){
                        setRedirigirCarro(true);
                    }
                })
                ActualizarCarro();
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="existe"){
                html_body = 
                <div>
                    El producto <b>ya se encuentra en el carro de compras.</b>
                </div>;
                Swal.fire({
                    title:"Producto en Carro",
                    html:html_body,
                    icon:"info",
                    showCancelButton:true,
                    cancelButtonText:"Aceptar",
                    cancelButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonColor:EstilosGlobales.colorPrimario,
                    confirmButtonText:"Ver Carro",
                    position: 'top-end'
                }).then((result)=>{
                    if(result.value){
                        setRedirigirCarro(true);
                    }
                })
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="no_existe"){
                html_body = 
                <div>
                    El producto <b>no existe en la base de datos.</b><br></br><br></br>
                    Recargue e intente nuevamente, si el problema persiste contáctese con soporte.
                </div>;
                Swal.fire({
                    title:"Producto no existe",
                    html:html_body,
                    icon:"error",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar"
                })
                setEstadoAgregarCarro("cargado");
            }else if(res[0]==="error_stock"){
                html_body = 
                <div>
                    La cantidad supera el <b>Stock</b> disponible.
                    {ConfigModulos.CarroCompras.agregar_carro.mensajes.sin_stock.html}
                </div>;
                Swal.fire({
                    title:"Agregar Producto",
                    html:html_body,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar",
                    position: 'top-end'
                })
                setEstadoAgregarCarro("cargado");            
            }else if(res[0]==="error_limitecompra"){
                html_body = 
                <div>
                    La cantidad supera el <b>limite máximo de unidades a comprar</b> de este producto.
                </div>;
                Swal.fire({
                    title:"Agregar Producto",
                    html:html_body,
                    icon:"warning",
                    confirmButtonColor:EstilosGlobales.colorSecundario,
                    confirmButtonText:"Aceptar",
                    position: 'top-end'
                })
                setEstadoAgregarCarro("cargado");
            }
        })
    }
    /* FNCartDN */
    
    //META

    return(
        <div>
            {
                RedirigirCarro?
                <Redirect to="/carro"></Redirect>:""
            }
            {
            DatosProducto.nombre?
                <MetaData titulo={DatosProducto.nombre.charAt(0)+DatosProducto.nombre.slice(1).toLowerCase()}/>:
                <MetaData/>
            }
            <Ruta className="d-none d-sm-block" ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div className="container py-2" style={{minHeight:window.innerHeight*0.6}}>
                    <div className="row">                        
                        <CarruselImagenes 
                            EstadoPadre={Estado} className={ConfigModulos.CatalogoProductos.producto_detalle.claseImagenes} DatosProducto={DatosProducto}>
                        </CarruselImagenes>
                        <DatosProductoPrincipal AgregarCarroPadre={{AgregarCarro}} CantidadPadre={{Cantidad,setCantidad}} EstadoPadre={Estado} className={ConfigModulos.CatalogoProductos.producto_detalle.claseDatosPrincipales} DatosProducto={DatosProducto}></DatosProductoPrincipal>
                    </div>
                    <ProductoEspecificacionContenido DatosProducto={DatosProducto}/>                    
                    <ProductosRelacionados Estado={{Estado}} SKU={DatosProducto.SKU}></ProductosRelacionados>                        
                </div>
            </div>
        </div>
    );
}
