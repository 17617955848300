import {Alert,Collapse,IconButton} from '@mui/material'
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export function useAlert(){
    const [AlertaProps,setAlertaProps] = useState({
        severity:"",
        display:false,
        content:""
    });

    return {AlertaProps,setAlertaProps}
}

export default function Alerta(props){
    let {AlertaProps,setAlertaProps} = props

    return(
        <Collapse in={AlertaProps?.display?true:false}>
            <Alert 
            className={props?.className?props.className:""}
            action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    if( AlertaProps.display ) setAlertaProps({...AlertaProps,display:false})
                  }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity={AlertaProps?.severity?AlertaProps.severity:"success"}>{AlertaProps?.content?AlertaProps.content:""}</Alert>
        </Collapse>
    )
}

